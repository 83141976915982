.blog-trending a.active,
h2.green {
  color: #face47;
}
@media (max-width: 768px) {
  .breadcrumbs-v1 {
    display: none;
  }
  #layerslider {
    display: none;
  }
  .cta-mobile {
    text-align: center;
    margin: 0 0 50px 0;
  }
}

.cta-mobile {
  min-width: 281px;
}

img.mobile-doctors {
  margin: 0 0 20px 20px;
}

.tt-query, /* UPDATE: newer versions use tt-input instead of tt-query */
.tt-hint {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.tt-query {
  /* UPDATE: newer versions use tt-input instead of tt-query */
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999;
}

.tt-menu {
  /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
  width: 422px;
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.tt-suggestion.tt-is-under-cursor {
  /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

.btn-u.btn-u-blue:focus,
.btn-u.btn-u-blue:active,
.btn-u.btn-u-blue.active,
.open .dropdown-toggle.btn-u.btn-u-blue {
  background: #3498db;
}

a#intro-movie,
a#intro-movie:focus {
  color: white;
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0;
  font-size: 16px;
  /* margin: 0 35%; */
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

/* Search */
#search-results {
  position: fixed;

  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-top: 94px;
  overflow-y: scroll;
  background-color: red;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
}

#search-results li a {
  font-size: 30px;
}

/* Accordion */
h3.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  transition: 0.4s;
}

h3.accordion.active,
h3.accordion:hover {
  background-color: #ddd;
}

div.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

h3.accordion:after {
  content: "\02795"; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

h3.accordion.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}

a.delete-vaccination {
  float: right;
  font-size: 16px;
}

.call-action-v1-in h1 {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0;
}

.call-action-v1-in.inner-btn.page-scroll {
  min-width: 280px;
}

.owl-carousel-v1.owl-work-v1 {
  margin: 0 0 -2px;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid #face47;
  font-size: 22px;
}

img.margin-right-15 {
  margin-right: 15px;
}

img.margin-left-10 {
  margin-left: 10px;
}

.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 5px;
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}

.youtube-player img:hover {
  -webkit-filter: brightness(75%);
}

.youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url("//i.imgur.com/TxzC70f.png") no-repeat;
  cursor: pointer;
}

/*Cookie Consent Begin*/
#cookie-consent {
  background-color: rgba(20, 20, 20, 0.8);
  font-size: 14px;
  color: #ccc;
  line-height: 40px;
  padding: 8px 0 8px 30px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

#cookie-consent a {
  text-decoration: none;
}

#close-cookie-consent {
  float: right;
  display: inline-block;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: -15px 0 0 0;
  font-weight: bold;
}

#close-cookie-consent:hover {
  color: #fff;
}

#cookie-consent a.cookie-consent-ok {
  background-color: #f1d600;
  color: #000;
  display: inline-block;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  float: right;
  margin: 0 60px 0 10px;
}

#cookie-consent a.cookie-consent-ok:hover {
  background-color: #e0c91f;
}
/*Cookie Consent End*/

article.pdf{
  list-style: none;
}

article.pdf li.title{
  font-size:15px;
  margin: 3px 0 3px 0;
  text-decoration: underline;
}