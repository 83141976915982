/*----------------------------------------------------------------------

[-----*** TABLE OF CONTENT ***-----]

1. Header - Navbar
2. Header - Menu Inner Right Style
3. Header - Responsive Navbar Style
4. Header - Submenu
5. Header - Mega Menu
6. Header - Fixed
6.1. Header - Fixed Boxed
7. Header - Border Bottom Style Option
8. Header - White Transparent Background Option
9. Header - Dark Transparent Background Option
10. Header - Dark On Scroll Option
11. Header - Dark Search Option
12. Header - Dark Dropdown Background Option
13. Header - Dark Responsive Navbar Option
14. Header - Classic White Option
15. Header - Classic Dark Option
16. Header - Hamburger

[--- HEADER STYLE CLASSES --]

1. header-v6
2. header-border-bottom
3. header-white-transparent
4. header-dark-transparent
5. header-dark-scroll
6. header-dark-search
7. header-dark-dropdown
8. header-dark-res-nav
9. header-dark-classic-white
10. header-dark-classic-dark
-----------------------------------------------------------------------*/


/*--------------------------------------------------
	[1. Header - Navbar]
----------------------------------------------------*/

/*Header v6*/
.header-v6 {
	z-index: 99;
	position: relative;
}

@media (max-width: 991px) {
	.header-v6 {
		position: static;
	}
}

/*Containers
------------------------------------*/
@media (min-width: 768px) and (max-width: 991px) {
  	.header-v6 .menu-container {
	    width: 750px;
  	}
}

@media (max-width: 991px) {
	.header-v6 .menu-container:before,
	.header-v6 .menu-container:after {
		content: " ";
		display: table;
	}

	.header-v6 .menu-container:after {
		clear: both;
	}

    .header-v6 .menu-container {
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	.header-v6 > .navbar > .container {
		width: inherit;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
    	padding-right: 0;
    }

	.header-v6 > .navbar > .container > .navbar-header,
	.header-v6 > .navbar > .container > .navbar-collapse {
	  	margin-left: 0;
	  	margin-right: 0;
	}
}

/*Navbar*/
.header-v6 .navbar {
	border: none;
	margin-bottom: 0;
}

/*Navbar Brand*/
.header-v6 .navbar-brand {
	height: 93px;
	max-height: 93px;
	line-height: 55px;
}

.header-v6 .navbar-brand img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	display: inline-block;
	vertical-align: middle;
}

.header-v6 .navbar-brand img.shrink-logo {
	display: none;
}

.header-v6 .navbar-brand img.default-logo {
	display: inherit;
}

/*Media Queries*/
@media (max-width: 991px) {
	.header-v6 .navbar-brand {
		padding-top: 20px;
	}

	.header-v6 .navbar-brand img.shrink-logo {
		display: inherit;
	}

	.header-v6 .navbar-brand img.default-logo {
		display: none;
	}
}

/*Navbar Toggle*/
.header-v6 .navbar-toggle {
	border: none;
	margin: 31px 0 31px 15px;
}

.header-v6 .navbar-toggle .icon-bar {
	height: 1px;
	width: 20px;
	font-size: 20px;
	background: #555;
	margin-bottom: 5px;
}

.header-v6 .navbar-toggle .icon-bar:last-child {
	margin-bottom: 0;
}

.header-v6 .navbar-toggle,
.header-v6 .navbar-toggle:hover,
.header-v6 .navbar-toggle:focus {
	background: inherit;
}

/*Navbar Collapse*/
.header-v6 .navbar-collapse {
	position: relative;
}

/*Navbar Menu*/
.header-v6 .navbar-nav > li > a {
	color: #fff;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.header-v6 .nav > li > a:hover,
.header-v6 .nav > li > a:focus {
	background: inherit;
}

@media (min-width: 992px) {
	.navbar {
		border-radius: 0;
	}

	.header-v6 .navbar-nav {
		float: right;
	}
}


/*--------------------------------------------------
	[2. Header - Menu Inner Right Style]
----------------------------------------------------*/

/*Menu Inner Right
------------------------------------*/
.header-v6 .header-inner-right {
	float: right;
	z-index: 999;
	margin-left: -20px;
	position: relative;
}

@media (max-width: 350px) {
	.header-v6 .header-inner-right {
		display: none;
	}
}

.header-v6 .menu-icons-list {
	margin: 0;
	list-style: none;
}

.header-v6 li.menu-icons {
	position: relative;
	display: inline-block;
}

.header-v6 li.menu-icons:first-child {
	padding-right: 15px;
}

.header-v6 li.menu-icons i.menu-icons-style {
	color: #fff;
	width: 25px;
	cursor: pointer;
	font-size: 16px;
	line-height: 94px;
	text-align: center;
	display: inline-block;
}

.header-v6 li.menu-icons span.badge {
	top: 28px;
	left: 12px;
	color: #fff;
	width: 18px;
	height: 18px;
	padding: 4px;
	display: block;
	font-size: 11px;
	text-align: center;
	position: absolute;
	background: #face47;
	display: inline-block;
	border-radius: 50% !important;
}

/*Shopping Cart*/
.header-v6 .shopping-cart .shopping-cart-open {
	right: 0;
	top: 94px;
	padding: 15px;
	display: none;
	min-width: 250px;
	background: #fff;
	position: absolute;
	border: 1px solid #e7e7e7;
	border-top: 2px solid #face47;
}

.header-v6 .shopping-cart:hover .shopping-cart-open {
	display: block;
}

.header-v6 .shopping-cart-open span.shc-title {
	color: #444;
	display: block;
	font-size: 12px;
	margin-bottom: 15px;
	padding-bottom: 10px;
	text-transform: uppercase;
	border-bottom: 1px solid #e7e7e7;
}

.header-v6 .shopping-cart-open span.shc-total {
	color: #444;
	float: right;
	font-size: 12px;
	margin-top: 6px;
	text-transform: uppercase;
}

.header-v6 .shopping-cart-open span.shc-total strong {
	padding-left: 3px;
}

.header-v6 .shopping-cart-open .btn-u {
	padding: 4px 12px;
}

/*Search Box
------------------------------------*/
.header-v6 .search-open {
	top: 0;
	right: 0;
	width: 100%;
	z-index: 99;
	height: auto;
	display: none;
	padding: 10px;
	position: fixed;
	background: #fff;
}

.header-v6 .search-open .form-control {
	color: #555;
	border: none;
	height: 75px;
	font-size: 33px;
	font-weight: 200;
	box-shadow: none;
	background: #fff;
	padding: 0 14% 0 13%;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
}

@media (max-width: 768px) {
	.header-v6 .search-open .form-control {
		font-size: 20px;
	}
}

@media (max-width: 991px) {
	.header-v6 .search-open .form-control {
		padding: 0 28% 0 5%;
	}
}

.header-v6 .search-open .form-control::-moz-placeholder {
	color: #555;
}
.header-v6 .search-open .form-control:-ms-input-placeholder {
	color: #555;
}
.header-v6 .search-open .form-control::-webkit-input-placeholder {
	color: #555;
}

.header-v6 .search-open .form-control:hover,
.header-v6 .search-open .form-control:active,
.header-v6 .search-open .form-control:focus {
	border: none;
	box-shadow: none;
}

.header-v6 .search-close {
	z-index: 999;
	position: relative;
}

.header-v6 li.menu-icons i.search-btn {
	color: #fff;
}

.header-v6 li.menu-icons i.fa-times {
	color: #444;
}


/*--------------------------------------------------
	[3. Header - Responsive Navbar Style]
----------------------------------------------------*/

/*Responsive Navbar*/
@media (max-width: 991px) {
	/*Responsive code for max-width: 991px*/
    .header-v6 .navbar-header {
        float: none;
    }

    .header-v6 .navbar-toggle {
        display: block;
    }

    .header-v6 .navbar-collapse {
		border-color: #eee;
    	border-bottom: 1px solid #eee;
    }

    .header-v6 .navbar-collapse.collapse {
        display: none !important;
    }

    .header-v6 .navbar-collapse.collapse.in {
        display: block !important;
  		overflow-y: auto !important;
    }

    .header-v6 .navbar-nav {
        margin: 0;
        float: none !important;
    }

    .header-v6 .navbar-nav > li {
        float: none;
    }

    .header-v6 .navbar-nav > li > a {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    /*Navbar Nav*/
    .header-v6 .navbar-nav > li > a {
    	color: #444;
    }

    /*Pull Right*/
    .header-v6 .dropdown-menu.pull-right {
		float: none !important;
	}

	/*Dropdown Menu Slide Down Effect*/
	.header-v6 .navbar-nav .open .dropdown-menu {
		border: 0;
		float: none;
		width: auto;
		margin-top: 0;
		position: static;
		box-shadow: none;
		background-color: transparent;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > li > a,
	.header-v6 .navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > li > a {
		line-height: 20px;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > li > a:focus {
		background-image: none;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > li > a {
		color: #777;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #face47;
		background-color: transparent;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > .active > a,
	.header-v6 .navbar-nav .open .dropdown-menu > .active > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #face47;
		background-color: transparent;
	}

	.header-v6 .navbar-nav .open .dropdown-menu > .disabled > a,
	.header-v6 .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #face47;
		background-color: transparent;
	}

	.header-v6 .dropdown-menu.no-bottom-space {
		padding-bottom: 0;
	}

  	/*Design for max-width: 991px*/
  	.header-v6 .navbar-collapse,
	.header-v6 .navbar-collapse .container {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.header-v6 .navbar-nav > li > a {
		font-size: 14px;
		padding: 12px 5px;
	}

	.header-v6 .navbar-nav > li a {
		border-bottom: none;
		border-top: 1px solid #eee;
	}

	.header-v6 .navbar-nav > li:first-child > a {
		border-top: transparent;
	}

	.header-v6 .navbar-nav > li > a:hover {
		color: #face47;
	}

	.header-v6 .navbar-nav > .open > a,
	.header-v6 .navbar-nav > .open > a:hover,
	.header-v6 .navbar-nav > .open > a:focus {
		color: #555;
		background: inherit;
	}

	.header-v6 .navbar-nav > .active > a,
	.header-v6 .navbar-nav > .active > a:hover,
	.header-v6 .navbar-nav > .active > a:focus {
		background: inherit;
		color: #face47 !important;
	}

	.header-v6 .dropdown .dropdown-submenu > a {
		font-size: 13px;
		color: #555 !important;
		text-transform: uppercase;
	}
}

@media (min-width: 992px) {
	/*Navbar Collapse*/
	.header-v6 .navbar-collapse {
		padding: 0;
	}

	/*Navbar*/
	.header-v6 .navbar {
		min-height: 40px !important;
	}

	.header-v6 .container > .navbar-header,
	.header-v6 .container-fluid > .navbar-header,
	.header-v6 .container > .navbar-collapse,
	.header-v6 .container-fluid > .navbar-collapse {
		margin-bottom: -10px;
	}

	.header-v6 .navbar-nav {
		top: 2px;
		position: relative;
	}

	.header-v6 .navbar-nav > li > a {
		padding: 20px;
		line-height: 54px;
	}

	.header-v6 .navbar-nav > li > a,
	.header-v6 .navbar-nav > li > a:hover,
	.header-v6 .navbar-nav > li > a:focus,
	.header-v6 .navbar-nav > .active > a,
	.header-v6 .navbar-nav > .active > a:hover,
	.header-v6 .navbar-nav > .active > a:focus {
		color: #fff;
		font-weight: 200;
		background: none;
	}

	.header-v6 .navbar-nav > .open > a,
	.header-v6 .navbar-nav > .open > a:hover,
	.header-v6 .navbar-nav > .open > a:focus {
		color: #fff;
	}

	/*Dropdown Menu*/
	.header-v6 .dropdown-menu {
		border: none;
		padding: 15px 0;
		min-width: 230px;
		border-radius: 0;
		background: #fff;
		z-index: 9999 !important;
		border-top: solid 3px #face47;
	}

	.header-v6 .dropdown-menu li a {
		color: #777;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 25px;
	}

	.header-v6 .dropdown-menu .active > a,
	.header-v6 .dropdown-menu li > a:hover {
		color: #face47;
		background: inherit;
		filter: none !important;
		transition: all .1s ease-in-out;
	}

	.header-v6 .dropdown-menu li > a:focus {
		background: none;
		filter: none !important;
	}

	.header-v6 .navbar-nav > li.dropdown:hover > .dropdown-menu {
		display: block;
	}

	.header-v6 .open > .dropdown-menu {
		display: none;
	}
}


/*--------------------------------------------------
	[4. Header - Submenu]
----------------------------------------------------*/

/*Dropdown Submenu for BS3
------------------------------------*/
.header-v6 .dropdown-submenu {
   position: relative;
}

.header-v6 .dropdown-submenu > a:after {
    top: 9px;
    right: 15px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
}

@media (max-width: 991px) {
  	.header-v6 .dropdown-submenu > a:after {
      	content: " ";
  	}
}

.header-v6 .dropdown-submenu > .dropdown-menu {
	top: -20px;
	left: 100%;
	margin-left: 0px;
}

/*Submenu comes from LEFT side*/
.header-v6 .dropdown-submenu > .dropdown-menu.submenu-left {
	left: -100%;
}

.header-v6 .dropdown-submenu:hover > .dropdown-menu {
   	display: block;
}

@media (max-width: 991px) {
	.header-v6 .dropdown-submenu > .dropdown-menu {
		display: block;
		margin-left: 15px;
	}
}

.header-v6 .dropdown-submenu.pull-left {
	float: none;
}

.header-v6 .dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
}

.header-v6 .dropdown-menu li [class^="fa-"],
.header-v6 .dropdown-menu li [class*=" fa-"] {
	left: -3px;
	width: 1.25em;
	margin-right: 1px;
	position: relative;
	text-align: center;
	display: inline-block;
}
.header-v6 .dropdown-menu li [class^="fa-"].fa-lg,
.header-v6 .dropdown-menu li [class*=" fa-"].fa-lg {
	/* increased font size for fa-lg */
	width: 1.5625em;
}


/*--------------------------------------------------
	[5. Header - Mega Menu]
----------------------------------------------------*/

/*Mega Menu
------------------------------------*/
.header-v6 .mega-menu .nav,
.header-v6 .mega-menu .dropup,
.header-v6 .mega-menu .dropdown,
.header-v6 .mega-menu .collapse {
  	position: static;
}

.header-v6 .mega-menu .navbar-inner,
.header-v6 .mega-menu .container {
  	position: relative;
}

.header-v6 .mega-menu .dropdown-menu {
  	left: auto;
}

.header-v6 .mega-menu .dropdown-menu > li {
  	display: block;
}

.header-v6 .mega-menu .dropdown-submenu .dropdown-menu {
  	left: 100%;
}

.header-v6 .mega-menu .nav.pull-right .dropdown-menu {
  	right: 0;
}

.header-v6 .mega-menu .mega-menu-content {
  	*zoom: 1;
  	padding: 0;
}

.header-v6 .mega-menu .mega-menu-content:before,
.header-v6 .mega-menu .mega-menu-content:after {
  	content: "";
  	display: table;
  	line-height: 0;
}

.header-v6 .mega-menu .mega-menu-content:after {
  	clear: both;
}

.header-v6 .mega-menu.navbar .nav > li > .dropdown-menu:after,
.header-v6 .mega-menu.navbar .nav > li > .dropdown-menu:before {
  	display: none;
}

.header-v6 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  	left: 0;
  	right: 0;
  	overflow: hidden;
}

@media (min-width: 992px) {
	.header-v6 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  		margin-left: 10px;
  		margin-right: 15px;
	}
}

/*Equal Height Lists*/
@media (min-width: 992px) {
	.header-v6 .mega-menu .equal-height {
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

	    /*-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;*/
	}

	.header-v6 .mega-menu .equal-height-in {
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}
}

/*Mega Menu Style Effect*/
.header-v6 .mega-menu .equal-height-list h3 {
	color: #555;
	font-size: 15px;
	font-weight: 400;
	padding: 0 10px 0 13px;
	text-transform: uppercase;
}

/*Space for only Big Resolution*/
@media (min-width: 992px) {
	.header-v6 .mega-menu .big-screen-space {
		margin-bottom: 20px;
	}
}

/*Mega Menu Content*/
@media (min-width: 992px) {
    .header-v6 .mega-menu .equal-height-in {
        padding: 20px 0;
        border-left: 1px solid #bbb;
    }

    .header-v6 .mega-menu .equal-height-in:first-child {
        border-left: none;
        margin-left: -1px;
    }

    .header-v6 .mega-menu .equal-height-list {
    	width: 100%;
    }

    .header-v6 .mega-menu .equal-height-list li a {
        display: block;
        position: relative;
        border-bottom: none;
        padding: 5px 10px 5px 15px;
    }

    .header-v6 .mega-menu .equal-height-list a:hover {
    	text-decoration: none;
    }

    /*Dropdown Arrow Icons*/
    .header-v6 .mega-menu .mega-menu-fullwidth li a {
    	padding: 5px 10px 5px 30px;
    }

    .header-v6 .mega-menu .mega-menu-fullwidth li a:after {
        top: 7px;
        left: 15px;
        font-size: 11px;
        content: "\f105";
        position: absolute;
        font-weight: normal;
        display: inline-block;
        font-family: FontAwesome;
    }

    /*Dropdown Arrow Icons Disable class*/
    .header-v6 .mega-menu .mega-menu-fullwidth .disable-icons li a {
    	padding: 5px 10px 5px 15px;
    }

    .header-v6 .mega-menu .mega-menu-fullwidth .disable-icons li a:after {
    	display: none;
    }
}

/*Space Effect inside the container to avoid scroll bar*/
@media (min-width: 737px) and (max-width: 991px) {
	.header-v6 .mega-menu .mega-menu-content .container {
		width: 690px !important;
	}

	.header-v6 .mega-menu .equal-height {
		margin-right: 0;
	}

	.header-v6 .mega-menu .equal-height-in {
    	padding-right: 0;
    	margin-right: -15px;
    }
}

@media (max-width: 991px) {
	.header-v6 .mega-menu .equal-height {
		margin-right: 0;
	}

	.header-v6 .mega-menu .equal-height-in {
    	padding-right: 0;
    	padding-left: 5px;
    }
}

/*Equal Hight List Style*/
@media (max-width: 991px) {
    .header-v6 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
        width: auto;
    }

    .header-v6 .mega-menu .equal-height-in,
    .header-v6 .mega-menu .equal-height-list {
        display: block;
    }

    .header-v6 .mega-menu .mega-menu-fullwidth .dropdown-menu > li > ul {
        display: block;
    }

    /*Equal Height List Style*/
    .header-v6 .mega-menu .equal-height-list h3 {
		color: #555;
		margin: 0 0 5px;
		font-size: 13px;
		font-weight: 400;
		padding: 6px 15px 5px;
	}

	.header-v6 .mega-menu .equal-height-list li a {
		color: #777;
		display: block;
		font-size: 13px;
		font-weight: 400;
		margin-left: 15px;
		padding: 6px 25px;
	}

	.header-v6 .mega-menu .equal-height-list > h3.active,
	.header-v6 .mega-menu .equal-height-list > .active > a,
	.header-v6 .mega-menu .equal-height-list > .active > a:hover,
	.header-v6 .mega-menu .equal-height-list > .active > a:focus {
		color: #face47;
		background-color: transparent;
	}

	.header-v6 .mega-menu .equal-height-list li a:hover {
		color: #face47;
		text-decoration: none;
	}
}


/*--------------------------------------------------
	[6. Header - Fixed]
----------------------------------------------------*/

/*Header Fixed
------------------------------------*/
/*Media Queries*/
@media (min-width: 992px) {
	/*Header Sticky*/
	.header-fixed .header-v6.header-sticky {
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		z-index: 999;
		position: fixed;
	}

	.header-fixed .header-v6.header-fixed-shrink {
		border-bottom: none;
		box-shadow: 0 0 3px #bbb;
	}

	.header-fixed .header-v6.header-fixed-shrink .navbar {
		background: #fff;
	}

	/*Navbar*/
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav > li > a {
		color: #555;
	}

	.header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
		color: #face47;
	}

	/*Navbar Brand*/
	.header-fixed .header-v6.header-fixed-shrink .navbar-brand img.default-logo {
		display: none;
	}

	.header-fixed .header-v6.header-fixed-shrink .navbar-brand img.shrink-logo {
		display: inherit;
	}

	/*Menu Inner Right*/
	.header-fixed .header-v6.header-fixed-shrink li.menu-icons i.menu-icons-style {
		color: #555;
	}

	/*Transition*/
	.header-fixed .header-v6.header-fixed-shrink .navbar-brand,
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav > li > a,
	.header-fixed .header-v6.header-fixed-shrink li.menu-icons i.menu-icons-style,
	.header-fixed .header-v6.header-fixed-shrink li.menu-icons span.badge,
	.header-fixed .header-v6.header-fixed-shrink .shopping-cart .shopping-cart-open,
	.header-fixed .header-v6.header-fixed-shrink .search-open .form-control {
  	transition: all 0.4s cubic-bezier(.7, 1, .7, 1);
	}

	/*Navbar Brand*/
	.header-fixed .header-v6.header-fixed-shrink .navbar-brand {
		height: 70px;
		max-height: 70px;
		line-height: 39px;
	}

	/*Navbar Nav*/
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav > li > a {
		line-height: 30px;
	}

	/*Menu Icons*/
	.header-fixed .header-v6.header-fixed-shrink li.menu-icons i.menu-icons-style {
		line-height: 70px;
	}

	.header-fixed .header-v6.header-fixed-shrink li.menu-icons span.badge {
		top: 17px;
	}

	.header-fixed .header-v6.header-fixed-shrink .shopping-cart .shopping-cart-open {
		top: 70px;
	}

	/*Search*/
	.header-fixed .header-v6.header-fixed-shrink .search-open {
		border-bottom: 1px solid #eee;
	}

	.header-fixed .header-v6.header-fixed-shrink .search-open .form-control {
		height: 49px;
		font-size: 25px;
	}
}

/*Media Queries*/
@media (max-width: 991px) {
	/*Header*/
	.header-fixed .header-v6,
	.header-fixed .header-v6.header-fixed-shrink {
		height: inherit;
		position: relative;
	}

	/*Header Inner Right*/
	.header-fixed .header-v6.header-fixed-shrink .header-inner-right,
	.header-fixed .header-v6.header-fixed-shrink .menu-icons-list {
		margin-left: 0;
		/*margin-right: 20px;*/
	}

	/*Menu Icons*/
	.header-v6 li.menu-icons i.menu-icons-style {
		color: #555;
	}
}


/*--------------------------------------------------
    [6.1. Header - Fixed Boxed]
----------------------------------------------------*/

.boxed-layout.header-fixed .header-v6.header-sticky {
	left: inherit;
	width: inherit;
}


/*--------------------------------------------------
	[7. Header - Border Bottom Style Option]
----------------------------------------------------*/

/*Navbar*/
.header-v6.header-border-bottom .navbar {
	box-shadow: none;
	border-bottom: 1px solid rgba(255,255,255,0.5);
}

.header-fixed .header-v6.header-border-bottom.header-fixed-shrink .navbar {
	border-bottom: none;
}

/*Media Queries*/
@media (min-width: 992px) {
	.header-v6.header-border-bottom .navbar {
		background: transparent;
	}

	.header-fixed .header-v6.header-border-bottom.header-fixed-shrink .navbar {
		background: #fff;
	}
}


/*--------------------------------------------------
	[8. Header - White Transparent Background Option]
----------------------------------------------------*/

/*Navbar*/
.header-v6.header-white-transparent .navbar {
	background: rgba(255,255,255,0.5);
}

/*Media Queries*/
@media (min-width: 992px) {
	.header-fixed .header-v6.header-white-transparent.header-fixed-shrink .navbar {
		background: #fff;
	}
}


/*--------------------------------------------------
	[9. Header - Dark Transparent Background Option]
----------------------------------------------------*/

/*Navbar*/
.header-v6.header-dark-transparent .navbar {
	background: rgba(0,0,0,.5);
}

/*Media Queries*/
@media (min-width: 992px) {
	/*Header*/
	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink {
		box-shadow: none;
	}

	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar {
		background: #2a2b2b;
	}

	/*Navbar Brand*/
	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-brand img.default-logo {
		display: inherit;
	}

	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-brand img.shrink-logo {
		display: none;
	}

	/*Navbar*/
	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-nav > li > a {
		color: #fff;
	}

	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-nav li > a:hover {
		color: #face47;
	}

	.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink li.menu-icons i.menu-icons-style {
		color: #fff;
	}
}

@media (max-width: 991px) {
	/*Header*/
	.header-fixed .header-v6.header-dark-transparent .navbar {
		background: #fff;
	}
}

/*Search*/
.header-v6.header-dark-transparent li.menu-icons i.fa-times,
.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink li.menu-icons i.fa-times {
	color: #555;
}


/*--------------------------------------------------
	[10. Header - Dark On Scroll Option]
----------------------------------------------------*/

/*Media Queries*/
@media (min-width: 992px) {
	/*Header*/
	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink {
		box-shadow: none;
		background: #2a2b2b;
	}

	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar {
		background-color: inherit;
	}

	/*Navbar Brand*/
	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-brand img.default-logo {
		display: inherit;
	}

	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-brand img.shrink-logo {
		display: none;
	}

	/*Navbar*/
	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav > li > a {
		color: #fff;
	}

	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav li > a:hover {
		color: #face47;
	}

	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink li.menu-icons i.menu-icons-style {
		color: #fff;
	}
}

/*Search*/
.header-v6.header-dark-scroll li.menu-icons i.fa-times,
.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink li.menu-icons i.fa-times {
	color: #555;
}


/*--------------------------------------------------
	[11. Header - Dark Search Option]
----------------------------------------------------*/

/*Media Queries*/
@media (min-width: 992px) {
	/*Navbar*/
	.header-fixed .header-v6.header-dark-search.header-fixed-shrink {
		box-shadow: none;
	}

	.header-fixed .header-v6.header-dark-search.header-dark-scroll.header-fixed-shrink .navbar {
		background: inherit;
	}

	.header-fixed .header-v6.header-dark-search.header-classic-white.header-fixed-shrink {
		box-shadow: none;
	}

	/*Search*/
	.header-v6.header-dark-search .search-open {
		background: #2a2b2b;
		border-bottom: 1px solid #555;
	}

	.header-fixed .header-v6.header-dark-search.header-fixed-shrink .search-open {
		border-bottom-color: #555;
	}

	.header-v6.header-dark-search .search-open .form-control {
		color: #fff;
		background: #2a2b2b;
	}

	.header-v6.header-dark-search .search-open .form-control::-moz-placeholder {
		color: #fff;
	}
	.header-v6.header-dark-search .search-open .form-control:-ms-input-placeholder {
		color: #fff;
	}
	.header-v6.header-dark-search .search-open .form-control::-webkit-input-placeholder {
		color: #fff;
	}

	.header-v6.header-dark-search li.menu-icons i.fa-times,
	.header-fixed .header-v6.header-dark-search.header-fixed-shrink li.menu-icons i.fa-times {
		color: #fff;
	}
}


/*--------------------------------------------------
	[12. Header - Dark Dropdown Option]
----------------------------------------------------*/

/*Shopping Cart*/
.header-v6.header-dark-dropdown .shopping-cart .shopping-cart-open {
	background: #2a2b2b;
	border-color: #555;
	border-top-color: #face47;
}

.header-v6.header-dark-dropdown .shopping-cart-open span.shc-title {
	color: #fff;
	border-bottom-color: #555;
}

.header-v6.header-dark-dropdown .shopping-cart-open span.shc-total {
	color: #fff;
}

/*Media Queries*/
@media (min-width: 992px) {
	/*Dropdown*/
	.header-v6.header-dark-dropdown .dropdown-menu {
		background: #2a2b2b;
	}

	.header-v6.header-dark-dropdown .dropdown-menu li a {
		color: #ccc;
	}

	.header-v6.header-dark-dropdown .dropdown-menu .active > a,
	.header-v6.header-dark-dropdown .dropdown-menu li > a:hover {
		color: #face47;
	}

	/*Equal Height List Style*/
	.header-v6.header-dark-dropdown .mega-menu .equal-height-in {
        border-left-color: #777;
    }

    .header-v6.header-dark-dropdown .mega-menu .equal-height-list h3 {
		color: #fff;
	}

	.header-v6.header-dark-dropdown .mega-menu .equal-height-list li a {
		color: #ccc;
	}

	.header-v6.header-dark-dropdown .mega-menu .equal-height-list .active > a,
	.header-v6.header-dark-dropdown .mega-menu .equal-height-list li a:hover {
		color: #face47;
	}
}


/*--------------------------------------------------
	[13. Header - Dark Responsive Navbar Option]
----------------------------------------------------*/

/*Media Queries*/
@media (max-width: 991px) {
	.header-v6.header-dark-res-nav {
		background: #2a2b2b;
	}

	/*Navbar Brand*/
	.header-fixed .header-v6.header-dark-res-nav .navbar-brand img.default-logo {
		display: inherit;
	}

	.header-fixed .header-v6.header-dark-res-nav .navbar-brand img.shrink-logo {
		display: none;
	}

	/*Toggle*/
	.header-v6.header-dark-res-nav .navbar-toggle .icon-bar {
		background: #fff;
	}

	/*Menu Inner Right*/
	.header-v6.header-dark-res-nav li.menu-icons i.menu-icons-style {
		color: #fff;
	}

	/*Search*/
	.header-v6.header-dark-res-nav .search-open {
		background: #2a2b2b;
		border-bottom: 1px solid #555;
	}

	.header-fixed .header-v6.header-dark-res-nav.header-fixed-shrink .search-open {
		border-bottom-color: #555;
	}

	.header-v6.header-dark-res-nav .search-open .form-control {
		color: #fff;
		background: #2a2b2b;
	}

	.header-v6.header-dark-res-nav .search-open .form-control::-moz-placeholder {
		color: #fff;
	}
	.header-v6.header-dark-res-nav .search-open .form-control:-ms-input-placeholder {
		color: #fff;
	}
	.header-v6.header-dark-res-nav .search-open .form-control::-webkit-input-placeholder {
		color: #fff;
	}

	.header-v6.header-dark-res-nav li.menu-icons i.fa-times,
	.header-fixed .header-v6.header-dark-res-nav.header-fixed-shrink li.menu-icons i.fa-times {
		color: #fff;
	}

	/*Navbar Collapse*/
	.header-v6.header-dark-res-nav .navbar-collapse {
		border-color: #555;
		border-bottom-color: #555;
	}

	/*Navbar Nav*/
	.header-v6.header-dark-res-nav .navbar-nav > li a {
		color: #ccc;
		border-top-color: #555;
	}

	.header-v6.header-dark-res-nav .navbar-nav > li a:hover {
		color: #face47;
	}

	.header-v6.header-dark-res-nav .navbar-nav > .open > a,
	.header-v6.header-dark-res-nav .navbar-nav > .open > a:hover,
	.header-v6.header-dark-res-nav .navbar-nav > .open > a:focus {
		color: #fff;
	}

	/*Dropdown*/
	.header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a {
		color: #ccc;
	}

	.header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a:hover {
		color: #face47;
	}

	/*Submenu*/
	.header-v6.header-dark-res-nav .dropdown .dropdown-submenu > a {
		color: #fff !important;
	}

	/*Mega Menu*/
	.header-v6.header-dark-res-nav .mega-menu .equal-height-list h3 {
		color: #fff;
	}
}


/*--------------------------------------------------
	[14. Header - Classic White Option]
----------------------------------------------------*/

/*Media Queries*/
@media (min-width: 992px) {
	/*Header Sticky*/
	.header-v6.header-classic-white .navbar {
		background: #fff;
	}

	/*Navbar*/
	.header-fixed .header-v6.header-classic-white .navbar-nav > li > a {
		color: #555;
		font-weight: 400;
	}

	.header-fixed .header-v6.header-classic-white .navbar-nav .active > a,
	.header-fixed .header-v6.header-classic-white .navbar-nav li > a:hover {
		color: #face47;
	}

	/*Navbar Brand*/
	.header-fixed .header-v6.header-classic-white .navbar-brand img.default-logo {
		display: none;
	}

	.header-fixed .header-v6.header-classic-white .navbar-brand img.shrink-logo {
		display: inherit;
	}

	/*Menu Inner Right*/
	.header-fixed .header-v6.header-classic-white li.menu-icons i.menu-icons-style {
		color: #555;
	}

	.header-fixed .header-v6.header-classic-white.header-dark-search li.menu-icons i.fa-times {
		color: #fff;
	}
}

/*--------------------------------------------------
	[15. Header - Classic Dark Option]
----------------------------------------------------*/

/*Shopping Cart*/
.header-v6.header-classic-dark .shopping-cart .shopping-cart-open {
	background: #2a2b2b;
	border-color: #555;
	border-top-color: #face47;
}

.header-v6.header-classic-dark .shopping-cart-open span.shc-title {
	color: #fff;
	border-bottom-color: #555;
}

.header-v6.header-classic-dark .shopping-cart-open span.shc-total {
	color: #fff;
}

/*Media Queries*/
@media (min-width: 992px) {
	/*Header*/
	.header-v6.header-classic-dark,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink {
		box-shadow: none;
		background: #2a2b2b;
	}

	.header-v6.header-classic-dark .navbar,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar {
		background-color: inherit;
	}

	/*Navbar Brand*/
	.header-v6.header-classic-dark .navbar-brand img.default-logo,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-brand img.default-logo {
		display: inherit;
	}

	.header-v6.header-classic-dark .navbar-brand img.shrink-logo,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-brand img.shrink-logo {
		display: none;
	}

	/*Navbar*/
	.header-v6.header-classic-dark .navbar-nav > li > a,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav > li > a {
		color: #fff;
	}

	.header-v6.header-classic-dark .navbar-nav .active > a,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav .active > a,
	.header-v6.header-classic-dark .navbar-nav li > a:hover,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav li > a:hover {
		color: #face47;
	}

	.header-v6.header-classic-dark li.menu-icons i.menu-icons-style,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink li.menu-icons i.menu-icons-style {
		color: #fff;
	}

	/*Search*/
	.header-v6.header-classic-dark .search-open {
		background: #2a2b2b;
	}

	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .search-open {
		border-bottom: none
	}

	.header-v6.header-classic-dark .search-open .form-control {
		color: #fff;
		background: #2a2b2b;
	}

	.header-v6.header-classic-dark .search-open .form-control::-moz-placeholder {
		color: #fff;
	}
	.header-v6.header-classic-dark .search-open .form-control:-ms-input-placeholder {
		color: #fff;
	}
	.header-v6.header-classic-dark .search-open .form-control::-webkit-input-placeholder {
		color: #fff;
	}

	.header-v6.header-classic-dark li.menu-icons i.fa-times,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink li.menu-icons i.fa-times {
		color: #fff;
	}

	/*Dropdown*/
	.header-v6.header-classic-dark .dropdown-menu {
		background: #2a2b2b;
	}

	.header-v6.header-classic-dark .dropdown-menu li a {
		color: #ccc;
	}

	.header-v6.header-classic-dark .dropdown-menu .active > a,
	.header-v6.header-classic-dark .dropdown-menu li > a:hover {
		color: #face47;
	}

	/*Equal Height List Style*/
	.header-v6.header-classic-dark .mega-menu .equal-height-in {
        border-left-color: #777;
    }

    .header-v6.header-classic-dark .mega-menu .equal-height-list h3 {
		color: #fff;
	}

	.header-v6.header-classic-dark .mega-menu .equal-height-list li a {
		color: #ccc;
	}

	.header-v6.header-classic-dark .mega-menu .equal-height-list .active > a,
	.header-v6.header-classic-dark .mega-menu .equal-height-list li a:hover {
		color: #face47;
	}
}

/*--------------------------------------------------
	[16. Header - Hamburger]
----------------------------------------------------*/
.header-v6 .sliding-panel__btn {
	display: block;
}

.header-v6 .sliding-panel__btn .icon-bar {
	background: #fff;
}
.header-v6 .sliding-panel__btn--dark .icon-bar {
	background: #555;
}

@media (min-width: 992px) {
	.header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn {
		margin-top: 20px;
		margin-bottom: 0;
	}

	.header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn .icon-bar {
		background: #555;
	}

	/*Transition*/
	.header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn,
	.header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn .icon-bar {
  	transition: all 0.4s cubic-bezier(.7, 1, .7, 1);
	}
}

@media (max-width: 991px) {
	.header-v6 .sliding-panel__btn .icon-bar {
		background: #555;
	}
}
