/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.9.2
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*[-----*** TABLE OF CONTENT ***-----]

1. Promo Blocks
2. Breadcrumbs
  2.1 - Breadcrumbs v1
  2.2 - Breadcrumbs v2
  2.3 - Breadcrumbs v3
3. Image Hover Effects
4. Block Grid
  4.1 - Block Grid v1
  4.2 - Block Grid v2
5. Services Box v1
6. Service Block
  6.1 - Service Block v1
  6.2 - Service Block v2
  6.3 - Service Block v3
  6.4 - Service Block v4
  6.5 - Service Block v5
  6.6 - Service Block v6
  6.7 - Service Block v7
  6.8 - Service Block v8
  6.# - Colored Content Boxes
  6.# - Funny Boxes
7. Process
8. Team
	8.1 - Team v1
	8.2 - Team v2
	8.3 - Team v3
	8.4 - Team v4
	8.5 - Team v5
	8.6 - Team v6
	8.7 - Team v7
9. Call To Action
  9.1 - Call To Action v1
  9.2 - Call To Action v2
10. Portfolio Box
  10.1 - Portfolio Box v1
11. Portfolio Boxes
	11.1 Portfolio Box v1
	11.2 Portfolio Box v2
12. Thumbnail
13. Image Backgrounds
  13.1 - Image Backgrounds v1
  13.2 - Image Backgrounds v2
14. Parallax Quote
15. Parallax Team
16. Parallax Counter
  16.1 - Parallax Counter v1
  16.2 - Parallax Counter v2
  16.3 - Parallax Counter v3
  16.4 - Parallax Counter v4
17. Flat Background Block
18. Flat Testimonials
19. Testimonials
  19.1 - Testimonials v4
  19.2 - Testimonials v5
  19.3 - Testimonials v6
  19.4 - Other Testimonials
  19.5 - Other Testimonials 1
20. Featured Blog
21. Blog Comments
22. Quote
	22.1 - Quote v2
23. Team Social
24. Box Shadows
25. Interactive Slider
  25.1 - Interactive Slider v1
  25.2 - Interactive Slider v2
26. Blog Trending
27. Blog Latest Posts
28. Blog Photostream
29. Newsletter
30. Blog Post Quote
31. Blog Post Author
32. Blog Comments
33. News
  33.1 - News v1
  33.2 - News v2
  33.3 - News v3
34. Blog Grid
35. Blog Thumb
36. Shop Subscribe
37. Parallax Slider (Main)
38. Master Slider
39. Revolution Slider
40. Layer Slider
41. Clients Carousel
42. Pie Chart
43. Counter
44. Back To Top
45. Owl Carousel
	45.1 - Recent Works (Owl Carousel)
	45.2 - Parallax Twitter (Owl Carousel)
	45.3 - Clients Section (Owl Carousel)
	45.4 - Owl Video
46. Bootstrap Carousels
	46.1 - Bootstrap Carousel v1
	46.2 - Bootstrap Carousel v2
47. Tabs
	47.1 - Tab v1
	47.2 - Tab v2
	47.3 - Tab v3
	47.4 - Tab v4
	47.5 - Tab v5
	47.6 - Tab v6
48. Accordions
49. Paginations
50. Pagers
	50.1 - Pagers Default
	50.2 - Pagers v2
	50.3 - Pagers v3
	50.4 - Pagers v4
51. Sidebar Menu
	51.1 - Sidebar Menu v1
52. Content Boxes
	52.1 - Content Boxes v1
	52.2 - Content Boxes v2
	52.3 - Content Boxes v3
	52.4 - Content Boxes v4
	52.5 - Content Boxes v5
	52.6 - Content Boxes v6
53. Tagline Boxes
	53.1 - Tagline Boxes v1
	53.2 - Tagline Boxes v2
	53.3 - Tagline Boxes v3
	53.4 - Tagline Boxes v4
	53.5 - Tagline Boxes v5
	53.6 - Tagline Boxes v6
	53.7 - Tagline Boxes v6
54. Tags
	54.1 - Tags v1
	54.2 - Tags v2
	54.3 - Tags v3
	54.4 - Tags v4

##. Main Page Styles

-------------------------------------------------------------*/

/*--------------------------------------------------
	[1. Promo Blocks]
----------------------------------------------------*/
/*Promo Background Images*/
.promo-bg-pattern-v1-dark {
  background: url(../assets/img/patterns/5.png) repeat;
}

.promo-bg-pattern-v1-light {
  background: url(../assets/img/patterns/16.png) repeat;
}

/*Promo Background Images*/
.promo-bg-img-v1 {
  background: url(../assets/img/bg/img1.jpg) no-repeat;
  background-size: cover;
}
.promo-bg-img-v2 {
  background: url(../assets/img/bg/img5.jpg) no-repeat;
  background-size: cover;
}
.promo-bg-img-v3 {
  background: url(../assets/img/bg/img10.jpg) no-repeat;
  background-size: cover;
}
.promo-bg-img-v4 {
  background: url(../assets/img/bg/img11.jpg) no-repeat;
  background-size: cover;
}
.promo-bg-img-v5 {
  background: url(../assets/img/bg/img6.jpg) no-repeat;
  background-size: cover;
}

/*Promo Background Fixed*/
.promo-bg-fixed {
  background-attachment: fixed;
}

/*Promo Texts v1*/
.promo-text-v1 {
  padding: 3px 0;
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  border-bottom: solid 2px #fff;
}

.promo-text-v1.color-dark {
  border-color: #555;
}

/*Promo Texts v2*/
.promo-text-v2 {
  font-size: 55px;
  font-weight: 600;
  line-height: 63px;
}

/*Promo Video Icon*/
.promo-video-icon-wrap {
  width: 64px;
  height: 64px;
  font-size: 18px;
  line-height: 70px;
  display: inline-block;
  transition: all .3s ease-in-out;
}

.promo-video-icon-wrap:hover {
  color: #333;
  text-decoration: none;
}

.promo-video-icon-wrap.color-light {
  color: #333;
  background: rgba(255,255,255,1);
}
.promo-video-icon-wrap.color-light:hover {
  background: rgba(255,255,255,.9);
}

.promo-video-icon-wrap.color-dark {
  color: #fff;
  background: rgba(0,0,0,.8);
}
.promo-video-icon-wrap.color-dark:hover {
  color: #fff;
  background: rgba(0,0,0,.9);
}

.promo-video-icon {
  left: 3px;
  position: relative;
}

/*--------------------------------------------------
	[2. Breadcrumbs]
----------------------------------------------------*/
/*Default Breadcrumbs*/
.breadcrumbs {
  overflow: hidden;
  padding: 10px 0 6px;
  border-bottom: solid 1px #eee;
  background: url(/assets/img/patterns/breadcrumbs.png) repeat;
}

.video-business-img1 {
  background: url(../video/youtube-business/video-business-img1.jpg) no-repeat center;
  background-size: cover; /*Scales the background image to be as large as possible*/
}

.breadcrumbs h1 {
  color: #666;
  font-size: 22px;
  margin-top: 8px;
}

.breadcrumb {
  top: 10px;
  z-index: 1;
  padding-right: 0;
  background: none;
  position: relative;
}
.breadcrumb a {
  color: #777;
}
.breadcrumb li.active,
.breadcrumb li a:hover {
  color: #face47;
  text-decoration: none;
}

@media (max-width: 550px) {
  .breadcrumbs h1.pull-left,
  .breadcrumbs ul.pull-right {
    width: 100%;
    text-align: center;
  }

  .breadcrumbs h1.pull-left {
    margin-bottom: 0;
  }

  .breadcrumbs .breadcrumb {
    top: 0;
    margin-bottom: 10px;
  }
}

/*Breadcrumbs Dark and Light*/
.breadcrumbs.breadcrumbs-dark,
.breadcrumbs.breadcrumbs-light {
  background: #222;
  padding: 30px 0 20px;
  border-bottom: solid 3px #777;
}

.breadcrumbs.breadcrumbs-light {
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
}

.breadcrumbs.breadcrumbs-dark h1,
.breadcrumbs.breadcrumbs-dark a,
.breadcrumbs.breadcrumbs-light h1,
.breadcrumbs.breadcrumbs-light a {
  color: #fff;
  font-weight: 200;
}

.breadcrumbs.breadcrumbs-light h1,
.breadcrumbs.breadcrumbs-light a {
  color: #555;
}

.breadcrumbs.breadcrumbs-dark h1 {
  font-size: 24px;
}

.breadcrumbs.breadcrumbs-light h1 {
  font-size: 26px;
}

/*Breadcrumbs Sizes*/
.breadcrumbs-sm {
  padding: 50px 0;
}
.breadcrumbs-md {
  padding: 100px 0;
}
.breadcrumbs-lg {
  padding: 200px 0;
}

/*Breadcrumbs v1 and v3
------------------------------------*/
.breadcrumbs-v1,
.breadcrumbs-v3 {
	transition: all 0.8s ease-in-out;
}

/*Breadcrumbs v1
------------------------------------*/
.breadcrumbs-v1 {
  padding: 100px 0;
  position: relative;
  background: url(../assets/img/breadcrumbs/img1.jpg) center no-repeat;
  background-size: cover; /*Scales the background image to be as large as possible*/
}

.breadcrumbs-v1:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.15);
}

.breadcrumbs-v1 span,
.breadcrumbs-v1 h1 {
  color: #fff;
  z-index: 1;
  position: relative;
}

.breadcrumbs-v1 span {
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 5px;
  display: inline-block;
}

.breadcrumbs-v1 h1 {
  font-size: 50px;
  margin-bottom: 0;
  line-height: 55px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .breadcrumbs-v1 {
    padding: 50px 0;
  }

  .breadcrumbs-v1 h1 {
    font-size: 40px;
    line-height: 45px;
  }
}

/*Breadcrumbs v2
------------------------------------*/
.breadcrumbs-v2 {
  text-align: center;
  position: relative;
  background: url(../assets/img/bg/11.jpg) no-repeat center;
}

.breadcrumbs-v2-in {
  padding: 80px 0;
  position: relative;
}

.breadcrumbs-v2 h1 {
  color: #fff;
  text-transform: uppercase;
}

.breadcrumb-v2 li a,
.breadcrumb-v2 li.active {
  color: #fff;
}

.breadcrumb-v2 li i {
  color: #fff;
  min-width: 19px;
  padding: 3px 7px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid #fff;
}

/*Breadcrumbs v3
------------------------------------*/
.breadcrumbs-v3 {
  z-index: 1;
  padding: 100px 0;
  position: relative;
}

.breadcrumbs-v3.breadcrumbs-lg {
  padding: 200px 0;
}

.breadcrumbs-v3.img-v1 {
  background: url(../assets/img/breadcrumbs/img1.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.breadcrumbs-v3.img-v2 {
  background: url(../assets/img/breadcrumbs/img2.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.breadcrumbs-v3.img-v3 {
  background: url(../assets/img/breadcrumbs/img3.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.breadcrumbs-v3:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.2);
}

.breadcrumbs-v3 h1 {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}

.breadcrumbs-v3 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 0;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 100px 0;
  }
}

@media (max-width: 768px) {
  .breadcrumbs-v3,
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 50px 0;
  }

  .breadcrumbs-v3 h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .breadcrumbs-v3 p {
    font-size: 18px;
  }
}

/*--------------------------------------------------
	[3. Image Hover Effects]
----------------------------------------------------*/
/*Image Hover v1*/
.img-hover-v1 span {
  display: block;
  overflow: hidden;
  position: relative;
}

.img-hover-v1 span:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

.img-hover-v1:hover span:after {
  background: rgba(0,0,0,0.3);
}

.img-hover-v1 img,
.img-hover-v2 img,
.img-hover-v1 span:after {
  transition: all 0.5s ease-in-out;
}

.img-hover-v1:hover img {
  -transform: scale(1.1);
}

/*Image Hover v2*/
.img-hover-v2 img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.img-hover-v2:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

/*--------------------------------------------------
	[4. Block Grid]
----------------------------------------------------*/
/*Block Grid v1
------------------------------------*/
.block-grid-v1 {
  background: #fff;
  padding: 15px 15px 5px;
  border-bottom: solid 2px #eee;
  transition: all 0.4s ease-in-out;
}

.block-grid-v1:hover {
  border-color: #face47;
}

.block-grid-v1 img {
  margin-bottom: 10px;
}

.block-grid-v1 img.block-grid-v1-img {
  width: 35px;
  height: 35px;
  margin-top: 8px;
}

.block-grid-v1.content-boxes-v1 img.pull-left {
  float: inherit !important;
}

.block-grid-v1 h3 a {
  font-size: 22px;
  font-weight: 200;
  margin-right: 10px;
}

.block-grid-v1.content-boxes-v1 h3 {
  margin-bottom: 0;
}

.block-grid-v1.content-boxes-v1 h3 a {
  color: #555;
}

/*Star Vote*/
.block-grid-v1 .star-vote {
  padding: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}

.block-grid-v1 .star-vote li {
  padding: 0;
  font-size: 11px;
  display: inline-block;
}

/*Additional Info*/
.block-grid-v1-add-info {
  margin: 0;
  padding-top: 15px;
  border-top: solid 1px #eee;
}

.block-grid-v1-add-info li {
  padding: 0;
  margin-right: 3px;
  margin-bottom: 10px;
}

.block-grid-v1-add-info li a {
  font-size: 11px;
  padding: 4px 7px;
  text-decoration: none;
  border: solid 2px #eee;
  border-radius: 3px !important;
}

/*Block Grid v2
------------------------------------*/
.block-grid-v2 {
  padding: 0;
}

.block-grid-v2 li {
	list-style: none;
	position: relative;
	-webkit-backface-visibility: hidden; /*For Chrome*/
}

.block-grid-v2-info {
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
  border-bottom: solid 3px #eee;
	transition: all 0.4s ease-in-out;
}

.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #face47;
}

.block-grid-v2-info h3 {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 10px;
}

.block-grid-v2-info .star-vote li {
  padding: 2px;
  font-size: 16px;
}

/*--------------------------------------------------
	[5. Services Box]
----------------------------------------------------*/
/* Services Box v1
------------------------------------*/
.service-box-v1 .service-block {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 20px;
}

.service-box-v1 p {
  position: relative;
  margin-bottom: 50px;
}

.service-box-v1 p:after {
  content: " ";
  left: 50%;
  height: 1px;
  width: 50px;
  bottom: -15px;
  position: absolute;
  margin-left: -25px;
  background: #face47;
}

.service-box-v1 ul {
  padding: 0 40px;
}

.service-box-v1 li {
  margin: 8px 0;
  font-size: 15px;
  padding-top: 8px;
  border-top: dotted 1px #face47;
}

.service-box-v1 li:hover {
  color: #face47;
}

.service-box-v1 li:first-child {
  padding-top: 0;
  border-top: none;
}

/*--------------------------------------------------
	[6. Service Block ]
----------------------------------------------------*/
/*Service Block v1
------------------------------------*/
.service-block-v1 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  padding: 45px 20px 20px;
  border: 1px solid #f2f2f2;
}

.service-block-v1 i {
  left: 50%;
  top: -30px;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 26px;
  line-height: 62px;
  margin-left: -30px;
  text-align: center;
  position: absolute;
  background: #face47;
  display: inline-block;
}

.service-block-v1 h3,
.service-block-v1 p {
  margin-bottom: 20px;
}

/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/
.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255,255,255,0.2);
}

.service-block-v2 .service-block-in i {
  font-size: 40px;
}

.service-block-v2 .service-block-in h4 {
  line-height: 25px;
}

.service-block-v2 .service-block-in p {
  margin-bottom: 20px;
}

.service-block-v2 .service-block-in i,
.service-block-v2 .service-block-in h4,
.service-block-v2 .service-block-in p {
  color: #fff;
}

/*Service Block v3 (used in profile pages)
------------------------------------*/
.service-block-v3 {
  padding: 20px;
}

.service-block-v3 i {
  color: #fff;
  float: left;
  font-size: 50px;
  margin: 0 20px 20px 0;
}

.service-block-v3 .service-heading,
.service-block-v3 .service-in small {
  color: #fff;
  opacity: 0.8;
  line-height: 1;
}

.service-block-v3 .service-in h4,
.service-block-v3 .counter {
  color: #fff;
}

.service-block-v3 .service-heading {
  font-size: 16px;
  text-transform: uppercase;
}

.service-block-v3 .counter {
  display: block;
  line-height: 1;
  font-size: 30px;
}

.service-block-v3 .progress {
  margin-bottom: 7px;
}

/*Service Innner*/
.service-block-v3 .service-in small {
  font-size: 16px;
  text-transform: uppercase;
}

.service-block-v3 .service-in h4 {
  font-size: 16px;
  line-height: 0.8;
  margin-bottom: 0;
}

/*Statistics*/
.service-block-v3 .statistics .heading-xs {
  color: #fff;
  opacity: 0.8;
}

.service-block-v3 .statistics small {
  color: #fff;
}

.service-block-v3 .statistics .progress {
  background: #bbb;
}

.service-block-v3 .statistics .progress-bar-light {
  background: #fff;
}

/*Service Block v4
------------------------------------*/
.service-block-v4 {
  position: relative;
  background: #f7f7f7;
}

.service-block-v4:after {
  top: 0;
  width: 0;
  height: 0;
  left: 50%;
  content: '';
  margin-left: -20px;
  position: absolute;
  border-style: solid;
  border-width: 25px 20px 0 20px;
  border-color: #fff transparent transparent transparent;
}

.service-block-v4 .service-desc {
  text-align: center;
}

.service-block-v4 .service-desc i {
  color: #face47;
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
}

.service-block-v4 .service-desc h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

/*Service Block v5
------------------------------------*/
.service-block-v5 {
  text-align: center;
  background: #f7f7f7;
}

.service-block-v5 .service-inner {
  padding: 80px 40px;
}

.service-block-v5 .service-inner i {
  margin-bottom: 30px;
}

.service-block-v5 .service-inner span {
  display: block;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.service-block-v5 .service-border {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

@media (max-width: 992px) {
  .service-block-v5 .service-border {
		border-left: none;
		border-right: none;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
  }
}

/*Service Block v6
------------------------------------*/
.service-block-v6 i {
  float: left;
  margin: 5px 15px 0 0;
}

.service-block-v6 .service-desc {
  overflow: hidden;
}

.service-block-v6 .service-desc h2 {
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.service-block-v6 .service-desc a {
  text-transform: uppercase;
}

/*Service Block v7
------------------------------------*/
.service-block-v7 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 45px 20px 20px;
}

.service-block-v7 i {
  left: 50%;
  top: -25px;
  color: #fff;
  width: 45px;
  height: 45px;
  font-size: 22px;
  line-height: 46px;
  text-align: center;
  position: absolute;
  background: #face47;
  margin-left: -22.5px;
  display: inline-block;
}

.service-block-v7 h3,
.service-block-v7 p {
    margin-bottom: 20px;
}

/*Service Block v8
------------------------------------*/
.service-block-v8 {
  padding: 25px;
  background: #fff;
}

.service-block-v8 i {
  color: #555;
  float: left;
  font-size: 22px;
  margin: 5px 25px 0 0;
}

.service-block-v8 .service-block-desc {
  overflow: hidden;
}

.service-block-v8 .service-block-desc h3 {
  font-size: 18px;
  margin: 0 0 30px;
  position: relative;
  text-transform: uppercase;
}

.service-block-v8 .service-block-desc h3:after {
  left: 0;
  top: 40px;
  height: 1px;
  width: 30px;
  content: " ";
  position: absolute;
  background: #face47;
}

/*Colored Content Boxes
------------------------------------*/
.service-block {
	padding: 60px 30px;
	text-align: center;
	margin-bottom: 20px;
}

.service-block p,
.service-block h2 {
	color: #fff;
}

.service-block h2 a:hover{
	text-decoration: none;
}

.service-block-light,
.service-block-default {
	background: #fafafa;
	border: solid 1px #eee;
}

.service-block-default:hover {
	box-shadow: 0 0 8px #eee;
}

.service-block-light p,
.service-block-light h2,
.service-block-default p,
.service-block-default h2 {
	color: #555;
}

.service-block-u {
	background: #face47;
}
.service-block-blue {
	background: #3498db;
}
.service-block-red {
	background: #e74c3c;
}
.service-block-sea {
	background: #1abc9c;
}
.service-block-grey {
	background: #95a5a6;
}
.service-block-yellow {
	background: #f1c40f;
}
.service-block-orange {
	background: #e67e22;
}
.service-block-green {
	background: #2ecc71;
}
.service-block-purple {
	background: #9b6bcc;
}
.service-block-aqua {
	background: #27d7e7;
}
.service-block-brown {
	background: #9c8061;
}
.service-block-dark-blue {
	background: #4765a0;
}
.service-block-light-green {
	background: #79d5b3;
}
.service-block-dark {
	background: #555;
}
.service-block-light {
	background: #ecf0f1;
}

/* Funny Boxes
------------------------------------*/
.funny-boxes {
	background: #f7f7f7;
	padding: 20px 20px 15px;
	transition:all 0.3s ease-in-out;
}

.funny-boxes h2 {
	margin-top: 0;
	font-size: 18px;
	line-height: 20px;
}

.funny-boxes h2 a {
	color: #555;
}

.funny-boxes p a {
	color: #face47;
}

.funny-boxes .funny-boxes-img li {
	font-size: 12px;
	margin-bottom: 2px;
}

.funny-boxes .funny-boxes-img li i {
	color: #face47;
	font-size: 12px;
	margin-right: 5px;
}

@media (max-width: 992px) {
	.funny-boxes .funny-boxes-img li {
		display: inline-block;
	}
}

.funny-boxes .funny-boxes-img img {
	margin: 5px 10px 15px 0;
}

.funny-boxes ul.funny-boxes-rating li {
	display: inline-block;
}

.funny-boxes ul.funny-boxes-rating li i {
	color: #f8be2c;
	cursor: pointer;
	font-size: 14px;
}

.funny-boxes ul.funny-boxes-rating li i:hover {
	color: #f8be2c;
}

/*Funny Colored Boxes*/
.funny-boxes-colored p,
.funny-boxes-colored h2 a,
.funny-boxes-colored .funny-boxes-img li,
.funny-boxes-colored .funny-boxes-img li i {
	color: #fff;
}

/*Red Funny Box*/
.funny-boxes-red {
	background: #e74c3c;
}

/*Dark Red Funny Box*/
.funny-boxes-purple {
	background: #9b6bcc;
}

/*Blue Funny Box*/
.funny-boxes-blue {
	background: #3498db;
}

/*Grey Funny Box*/
.funny-boxes-grey {
	background: #95a5a6;
}

/*Turquoise Funny Box*/
.funny-boxes-sea {
	background: #1abc9c;
}

/*Turquoise Top Bordered Funny Box*/
.funny-boxes-top-sea {
	border-top: solid 2px #1abc9c;
}

.funny-boxes-top-sea:hover {
	border-top-color: #16a085;
}

/*Yellow Top Bordered Funny Box**/
.funny-boxes-top-yellow {
	border-top: solid 2px #f1c40f;
}

.funny-boxes-top-yellow:hover {
	border-top-color: #f39c12;
}

/*Red Top Bordered Funny Box**/
.funny-boxes-top-red {
	border-top: solid 2px #e74c3c;
}

.funny-boxes-top-red:hover {
	border-top-color: #c0392b;
}

/*Purple Top Bordered Funny Box**/
.funny-boxes-top-purple {
	border-top: solid 2px #9b6bcc;
}

.funny-boxes-top-purple:hover {
	border-top-color: #814fb5;
}

/*Orange Left Bordered Funny Box**/
.funny-boxes-left-orange {
	border-left: solid 2px #e67e22;
}

.funny-boxes-left-orange:hover {
	border-left-color: #d35400;
}

/*Green Left Bordered Funny Box**/
.funny-boxes-left-green {
	border-left: solid 2px #face47;
}

.funny-boxes-left-green:hover {
	border-left-color: #5fb611;
}

/*Blue Left Bordered Funny Box**/
.funny-boxes-left-blue {
	border-left: solid 2px #3498db;
}

.funny-boxes-left-blue:hover {
	border-left-color: #2980b9;
}

/*Dark Left Bordered Funny Box**/
.funny-boxes-left-dark {
	border-left: solid 2px #555;
}

.funny-boxes-left-dark:hover {
	border-left-color: #333;
}

/*--------------------------------------------------
	[7. Process]
----------------------------------------------------*/
/*Process v1
------------------------------------*/
.process-v1 .process-in {
  text-align: center;
}

.process-v1 .process-in > li i {
  z-index: 1;
  width: 30px;
  height: 30px;
  position: relative;
  text-align: center;
  background: #face47;
  margin-bottom: 15px;
  display: inline-block;
  border: solid 9px #fff;
}

.process-v1 .process-in > li i:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #bbb;
}

/*Process Description*/
.process-v1 .process-in .process-desc {
  padding: 0 20px;
}

.process-v1 .process-in > li h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  text-transform: uppercase;
}

/*Process Border*/
.process-v1 .process-border {
  position: relative;
}

.process-v1 .process-border:before {
  top: 15px;
  width: 75%;
  left: 12%;
  content: " ";
  display: block;
  position: absolute;
  border-top: solid 2px #ddd;
}

@media (max-width: 768px) {
  .process-v1 .process-border:before {
    border: none;
  }
}

/*--------------------------------------------------
	[8. Team]
----------------------------------------------------*/
/* Team v1
------------------------------------*/
.team-v1 ul {
  position: relative;
}

/*Team Image*/
.team-v1 li > .team-img {
  position: relative;
}

.team-v1 li > .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: transparent;
  transition: all 0.4s ease-in-out;
}

.team-v1 li:hover > .team-img:after {
  background: rgba(0,0,0,0.5);
}

/*Team Socials*/
.team-v1 li > .team-img ul {
  left: 0;
  top: 45%;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 36px;
  list-style: none;
  margin-top: -18px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

.team-v1 li:hover > .team-img ul {
  top: 50%;
  visibility: visible;
}

.team-v1 li > .team-img ul li {
  margin: 0 5px;
  text-align: center;
  display: inline-block;
}

.team-v1 li > .team-img li i {
  color: #eee;
  border-width: 2px;
  border-color: #eee;
  transition: all 0.1s ease-in-out;
}

.team-v1 li > .team-img li i.fa-twitter:hover {
  color: #fff;
  background: #3498db;
  border-color: #3498db;
}

.team-v1 li > .team-img li i.fa-facebook:hover {
  color: #fff;
  background: #4765a0;
  border-color: #4765a0;
}

.team-v1 li > .team-img li i.fa-google-plus:hover {
  color: #fff;
  background: #e74c3c;
  border-color: #e74c3c;
}

/*Team Discription*/
.team-v1 li > h3 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  margin: 15px 0 7px;
  text-transform: uppercase;
}

.team-v1 li > h4 {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

.team-v1 li > p {
  margin: 20px 0;
  position: relative;
}

.team-v1 li > p:before {
  left: 0;
  top: -10px;
  height: 1px;
  width: 40px;
  content: " ";
  position: absolute;
  background: #bbb;
  transition: all 0.4s ease-in-out;
}

.team-v1 li:hover > p:before {
  width: 100%;
  background: #face47;
}

/*Team v2
------------------------------------*/
.team-v2 {
  position: relative;
  margin-bottom: 20px;
}

.team-v2 img {
  width: 100%;
}

.team-v2 .inner-team {
  padding: 20px;
  background: #fff;
  text-align: center;
}

.team-v2 .inner-team h3 {
  margin: 0;
}

.team-v2 .inner-team small {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
}

.team-v2 .inner-team p {
  font-size: 13px;
}

.team-v2 .inner-team hr {
  margin: 10px 0 15px;
}

/*Team-Social (Temporary)*/
.team-social {
  margin-bottom: 0;
}

.team-social li {
  padding: 0 2px;
}

.team-social li a {
  font-size: 14px;
  padding: 6px 4px;
  text-align: center;
  border-radius: 50% !important;
}

.team-social li i {
  min-width: 20px;
}

.team-social li a.fb {
  color: #4862a3;
  border: 1px solid #4862a3;
}

.team-social li a.tw {
  color: #159ceb;
  border: 1px solid #159ceb;
}

.team-social li a.gp {
  color: #dc4a38;
  border: 1px solid #dc4a38;
}

.team-social li a:hover {
  text-decoration: none;
}

.team-social li a.fb:hover {
  color: #fff;
  background: #4862a3;
}

.team-social li a.tw:hover {
  color: #fff;
  background: #159ceb;
}

.team-social li a.gp:hover {
  color: #fff;
  background: #dc4a38;
}

/*Team v3
------------------------------------*/
.team-v3 .team-img {
  position: relative;
}

.team-v3 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
}

.team-v3 .team-img:hover:after {
  background: rgba(0,0,0,0.5);
  transition: all 0.2s ease-in-out;
}

.team-v3 .team-img > img {
  width: 100%;
}

/*Team Hover Gradient*/
.team-v3 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  padding: 20px;
  list-style: none;
  margin-top: -90px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

.team-v3 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible;
}

.team-v3 .team-hover span,
.team-v3 .team-hover small {
  color: #fff;
  display: block;
}

.team-v3 .team-hover span {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

.team-v3 .team-hover small {
  font-size: 13px;
  font-weight: 200;
  margin-bottom: 10px;
}

.team-v3 .team-hover p {
  color: #fff;
  font-weight: 200;
  margin-bottom: 20px;
}

/*Team Social*/
.team-v3 .team-hover .team-social-v3 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff;
}

.team-v3 .team-hover .team-social-v3 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Team v4
------------------------------------*/
.team-v4 {
  text-align: center;
}

.team-v4 img {
  width: 100%;
  margin-bottom: 20px;
}

.team-v4 span {
  color: #333;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.team-v4 small {
  color: #999;
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px;
}

.team-v4 .team-social-v4 a {
  color: #555;
  font-size: 16px;
}

.team-v4 .team-social-v4 a:hover {
  color: #face47;
  text-decoration: none;
}

/*Team v5
------------------------------------*/
.team-v5 {
  text-align: center;
}

.team-v5 span {
  color: #555;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.team-v5 small {
  display: block;
  color: #face47;
  font-size: 13px;
  font-style: italic;
  position: relative;
  margin-bottom: 25px;
}

.team-v5 small:after {
  left: 50%;
  top: 30px;
  height: 1px;
  width: 30px;
  content: " ";
  background: #777;
  margin-left: -15px;
  position: absolute;
}

.team-v5 .team-img {
  position: relative;
}

.team-v5 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
}

.team-v5 .team-img:hover:after {
  background: rgba(0,0,0,0.5);
  transition: all 0.2s ease-in-out;
}

.team-v5 .team-img > img {
  width: 100%;
  margin-bottom: 20px;
}

/*Team Hover Gradient*/
.team-v5 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  list-style: none;
  margin-top: -16px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

.team-v5 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible;
}

/*Social Network-*/
.team-v5 .team-hover .team-social-v5 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff;
}

.team-v5 .team-hover .team-social-v5 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Team v6
------------------------------------*/
.team-v6 {
  text-align: center;
}

.team-v6 img {
  width: 100%;
  margin-bottom: 20px;
}

.team-v6 span {
  color: #777;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.team-v6 small {
  display: block;
  color: #face47;
  font-size: 13px;
  margin-bottom: 15px;
}

/*Team v7
------------------------------------*/
.team-v7 .team-v7-in {
  padding: 0 50px;
}

.team-v7 .team-v7-name {
  color: #555;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.team-v7 .team-v7-position {
  display: block;
  color: #face47;
  font-style: normal;
  margin-bottom: 15px;
}

.team-v7 p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}

/*Team Space*/
.team-v7 .team-v7-name {
  margin-top: 60px;
}

.team-v7 .social-icons-v1 {
  margin-bottom: 60px;
}

/*Team Image*/
.team-v7 .team-v7-img {
  position: relative;
}

.team-v7 .team-v7-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.1);
}

/*Team Info*/
.team-v7 .team-arrow-right,
.team-v7 .team-arrow-left {
  position: relative;
}

.team-v7 .team-arrow-left:before,
.team-v7 .team-arrow-right:before {
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -25px;
  z-index: 2;
  content: " ";
  position: absolute;
  border-style: solid;
}

.team-v7 .team-arrow-right:before {
  right: -25px;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent #fff;
}

.team-v7 .team-arrow-left:before {
  left: -25px;
  border-width: 20px 25px 20px 0;
  border-color: transparent #fff transparent transparent;
}

/*Team Info Media Queries*/
@media (max-width: 991px) {
  /*Team Arrows*/
  .team-v7 .team-arrow-left:before,
  .team-v7 .team-arrow-right:before {
    top: auto;
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent;
  }

  .team-v7 .team-arrow-left:before,
  .team-v7 .team-arrow-right:before {
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
  }

  /*Equal Height Columns*/
  .team-v7 .equal-height-column {
    height: auto !important;
  }
}

/*--------------------------------------------------
	[9. Call To Action]
----------------------------------------------------*/
/*Call To Action v1
------------------------------------*/
.call-action-v1 p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0;
}

.call-action-v1.call-action-v1-boxed {
  padding: 0 25px;
}

.call-action-v1 .call-action-v1-box {
  width: 100%;
  display: table;
  padding: 30px 0;
  vertical-align: middle;
}

.call-action-v1 .call-action-v1-in {
  display: table-cell;
  vertical-align: middle;
}

.call-action-v1 .call-action-v1-in.inner-btn {
  padding-top: 1px;
  min-width: 250px;
  text-align: right;
}

@media (max-width: 992px) {
  .call-action-v1 .call-action-v1-in,
  .call-action-v1 .call-action-v1-in.inner-btn {
    display: block;
    text-align: center;
  }

  .call-action-v1 .call-action-v1-in p {
    margin-bottom: 20px;
  }
}

/*Call To Action v2
------------------------------------*/
.call-action-v2 {
  padding: 50px 0;
  text-align: center;
  background: url(../assets/img/patterns/16.png);
}

.call-action-v2 h2 {
  font-size: 22px;
  text-transform: uppercase;
}

.call-action-v2 p {
  padding: 0 100px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .call-action-v2 p {
    padding: inherit;
  }
}

/*--------------------------------------------------
	[10. Portfolio Box]
----------------------------------------------------*/
/*Portfolio Box*/
@media (min-width: 991px) and (max-width: 1199px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .portfolio-box {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}

.portfolio-box a.fancybox {
  overflow: hidden;
  position: relative;
}

.portfolio-box a.fancybox:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

.portfolio-box a.fancybox:hover:after {
  background: rgba(0,0,0,0.5);
}

.portfolio-box a.fancybox img {
  transition: all .5s ease;
}

.portfolio-box a.fancybox:hover img {
  transform: scale(1.1);
}

.portfolio-box .portfolio-box-in {
	visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
	background: #face47;
	font-size: 20px;
	text-align: center;
	line-height: 53px;
	width: 50px;
	height: 50px;
  margin-top: -25px;
  margin-left: -25px;
}

.portfolio-box a.fancybox:hover .portfolio-box-in {
  visibility: visible;
}

/*Project Tag*/
.portfolio-box small.project-tag {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}

.portfolio-box small.project-tag,
.portfolio-box small.project-tag a {
  color: #999;
}

.portfolio-box small.project-tag i {
  margin-right: 5px;
}

.portfolio-box small.project-tag a:hover {
  color: #face47;
}

/*--------------------------------------------------
	[11. Portfolio Boxes]
----------------------------------------------------*/
/* Portfolio Box v1
------------------------------------*/
.portfolio-box-v1 {
  margin: 0;
}

.portfolio-box-v1 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}

.portfolio-box-v1 li {
  background: #333;
  position: relative;
    -webkit-backface-visibility: hidden; /*For Chrome*/
}

.portfolio-box-v1 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

.portfolio-box-v1 li:hover:after {
  background: rgba(0,0,0,0.7);
}

.portfolio-box-v1 li:after,
.portfolio-box-v1 li:hover:after {
  transition: all 0.3s ease-in-out;
}

.portfolio-box-v1 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.portfolio-box-v1 li:hover img {
  -transform: scale(1.1);
}

.portfolio-box-v1 .portfolio-box-v1-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 150px;
  margin-top: -75px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}

.portfolio-box-v1 li:hover .portfolio-box-v1-in {
  visibility: visible;
}

.portfolio-box-v1 .portfolio-box-v1-in h3 {
  font-size: 22px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.portfolio-box-v1 .portfolio-box-v1-in h3:after {
  left: 50%;
  height: 1px;
  width: 40px;
  content: " ";
  bottom: -10px;
  background: #fff;
  margin-left: -20px;
  position: absolute;
}

.portfolio-box-v1 .portfolio-box-v1-in p,
.portfolio-box-v1 .portfolio-box-v1-in h3 {
  color: #fff;
}

.portfolio-box-v1 .portfolio-box-v1-in p {
  font-size: 16px;
  margin-bottom: 20px;
}

.portfolio-box-v1 .portfolio-box-v1-in a.btn-u {
  font-weight: normal;
  -webkit-backface-visibility: hidden; /*For Chrome*/
}

/*Portfolio Box v2
------------------------------------*/
.portfolio-box-v2 {
  margin: 0 0 60px;
}

.portfolio-box-v2 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}

.portfolio-box-v2 li {
  background: #333;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden; /*For Chrome*/
}

.portfolio-box-v2 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

.portfolio-box-v2 li:hover:after {
	background: rgba(255,255,255,0.6);
}

.portfolio-box-v2 li:after,
.portfolio-box-v2 li:hover:after {
  transition: all 0.3s ease-in-out;
}

.portfolio-box-v2 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -transform: scale(1.1);
}

.portfolio-box-v2 .portfolio-box-v2-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 60px;
  margin-top: -30px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}

.portfolio-box-v2 li:hover .portfolio-box-v2-in {
	visibility: visible;
}

.portfolio-box-v2 .portfolio-box-v2-in i {
	color: #fff;
	width: 60px;
	height: 60px;
	padding: 20px;
	font-size: 22px;
	cursor: pointer;
	margin-right: 5px;
	text-align: center;
	display: inline-block;
	background: rgba(114,192,44,0.8);
}

.portfolio-box-v2 .portfolio-box-v2-in i:hover {
	background: #face47;
}

/*--------------------------------------------------
	[12. Thumbnail]
----------------------------------------------------*/
/*Thumbnail v1
------------------------------------*/
.thumbnails-v1 .thumbnail-img {
  margin-bottom: 12px;
}

.thumbnails-v1 h3 {
  margin-bottom: 5px;
}

.thumbnails-v1 h3 a {
  color: #555;
  font-size: 15px;
  text-transform: uppercase;
}

.thumbnails-v1 .read-more {
  color: #face47;
  font-size: 12px;
  text-transform: uppercase;
}

/*--------------------------------------------------
	[13. Image Backgrounds]
----------------------------------------------------*/
/* Image Backgrounds v1
------------------------------------*/
.bg-image-v1 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../assets/img/bg/11.jpg) repeat fixed;
  background-size: cover;
}

.bg-image-v1:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.5);
}

/*Image Backgrounds v2
------------------------------------*/
.bg-image-v2 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../assets/img/bg/7.jpg) repeat fixed;
}

.bg-image-v2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255,255,255,0.7);
}

/*Background Dark Cover*/
.bg-image-v2-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.5);
}

.bg-image-v2-dark h2,
.bg-image-v2-dark p {
  color: #eee !important;
}

/*--------------------------------------------------
	[14. Parallax Quote]
----------------------------------------------------*/
.parallax-quote {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: url(../assets/img/team/faces.jpg) 50% 0 repeat fixed;
}

.parallax-quote:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.8);
}

.parallax-quote:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../assets/img/patterns/gridtile.png) repeat;
}

/*Parallax Quote Inner*/
.parallax-quote-in {
  z-index: 1;
  padding: 0 80px;
  position: relative;
}

.parallax-quote-in p {
  color: #fff;
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

.parallax-quote-in p:after,
.parallax-quote-in p:before {
  content: ' " ';
  position: absolute;
  font-family: Tahoma;
}

.parallax-quote-in p:after {
  margin-left: 3px;
}

.parallax-quote-in p:before {
  margin-left: -15px;
}

.parallax-quote-in small {
  color: #bbb;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 768px){
  .parallax-quote-in {
    padding: 0 40px;
  }

  .parallax-quote-in p {
    font-size: 20px;
  }
}

/*Parallax Quote Light*/
.parallax-quote-light:after {
  background: rgba(255,255,255,0.9);
}

.parallax-quote-light .parallax-quote-in {
  background: #fff;
  padding: 30px 80px;
  border-bottom: solid 2px #ddd;
}

.parallax-quote-light .parallax-quote-in p {
  color: #555;
  font-size: 28px;
  font-weight: 200;
}

.parallax-quote-light .parallax-quote-in small {
  color: #555;
}

@media (max-width: 768px){
  .parallax-quote-light .parallax-quote-in {
    padding: 30px 40px;
  }

  .parallax-quote-light .parallax-quote-in p {
    font-size: 20px;
  }

  .parallax-quote-light .parallax-quote-in small {
    font-weight: 200;
  }
}

/*--------------------------------------------------
	[15. Parallax Team]
----------------------------------------------------*/
.parallax-team {
  background: url(../assets/img/team/faces.jpg) 50% 0 repeat fixed;
}

.parallax-team,
.parallax-team .title-box-v2 {
  position: relative;
}

.parallax-team:before,
.parallax-team-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255,255,255,0.9);
}

/*Parallax Team*/
.parallax-team {
  background: url(../assets/img/team/faces.jpg) 50% 0 repeat fixed;
}

.parallax-team,
.parallax-team .title-box-v2 {
  position: relative;
}

.parallax-team-dark:before {
  background: rgba(0,0,0,0.8);
}

/*--------------------------------------------------
	[16. Parallax Counter]
----------------------------------------------------*/
.parallax-bg {
  background: url(../assets/img/patterns/5.png) repeat fixed;
}

/*Parallax Counter
------------------------------------*/
.parallax-counter {
  padding: 30px 0;
  background: url(../assets/img/patterns/5.png) repeat fixed;
}

.parallax-counter .counters {
  color: #fff;
  padding: 15px 0;
}

.parallax-counter h4 {
  color: #999;
  font-size: 16px;
  text-transform: uppercase;
}

.parallax-counter span.counter {
  font-size: 48px;
}

/*Parallax Counter v1
------------------------------------*/
.parallax-counter-v1 {
  padding: 60px 0;
  background: url(../assets/img/patterns/5.png) repeat fixed;
}

.parallax-counter-v1 .counters {
  color: #fff;
  padding: 15px 0 10px;
  border: solid 1px #555;
}

.parallax-counter-v1 h4 {
  color: #eee;
  font-size: 16px;
  text-transform: uppercase;
}

.parallax-counter-v1 span.counter {
  font-size: 42px;
  line-height: 48px;
}

/*Parallax Counter v2
------------------------------------*/
.parallax-counter-v2 {
  position: relative;
  padding: 80px 0 60px;
  background: url(../assets/img/patterns/16.png) repeat fixed;
}

.parallax-counter-v2:after {
  top: 0;
  left: 50%;
  content: " ";
  margin-left: -25px;
  position: absolute;
  border-top: 25px solid #fff;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.parallax-counter-v2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.02);
}

.parallax-counter-v2 .counters {
  padding: 20px 0;
  border-bottom: solid 2px #ddd;
  background: rgba(255,255,255,0.9);
}

.parallax-counter-v2 .counters h4,
.parallax-counter-v2 .counters span {
  color: #555;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}

.parallax-counter-v2 .counters span {
  font-size: 36px;
}

.parallax-counter-v2 .counters h4 {
  font-size: 22px;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .parallax-counter-v2 .counters {
    margin-bottom: 0 !important;
  }
}

/*Parallax Counter v3
------------------------------------*/
.parallax-counter-v3 {
	z-index: 1;
	height: auto;
	padding: 80px 0;
	overflow: hidden;
	text-align: center;
	position: relative;
	background: url(../assets/img/bg/25.jpg) 50% 0 fixed;
}

.parallax-counter-v3:after {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: " ";
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0,0,0,0.5);
}

.parallax-counter-v3 .features {
  width: 130px;
  height: 130px;
  padding: 25px;
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 50% !important;
  background: rgba(255,255,255,0.2);
}

.parallax-counter-v3 .features:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #fff;
}

.parallax-counter-v3 .features span {
  color: #fff;
  display: block;
  font-weight: 200;
}

.parallax-counter-v3 .features span.counter {
  font-size: 30px;
}

.parallax-counter-v3 .features span.features-info {
  text-transform: uppercase;
}

@media (max-width: 550px) {
  .parallax-counter-v3 .features {
    width: 110px;
    height: 110px;
    padding: 22px;
  }

  .parallax-counter-v3 .features:after {
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
  }

  .parallax-counter-v3 .features span.counter {
    font-size: 20px;
  }

  .parallax-counter-v3 .features span.features-info {
    font-size: 11px;
  }
}

/*Parallax Counter v4
------------------------------------*/
.parallax-counter-v4 {
  z-index: 1;
  height: auto;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../assets/img/bg/19.jpg) 50% 0 fixed;
}

.parallax-counter-v4:after {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: " ";
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0,0,0,0.5);
}

.parallax-counter-v4 i {
  color: #fff;
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
}

.parallax-counter-v4 span.counter {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

.parallax-counter-v4 h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
}

/*--------------------------------------------------
	[17. Flat Background Block]
----------------------------------------------------*/
.flat-bg-block-v1 {
  padding: 40px 0;
  background: url(../assets/img/patterns/16.png) repeat;
}

.flat-bg-block-v1 .checked-list.first-child {
  float: left;
  margin-right: 100px;
}

.flat-bg-block-v1 .checked-list li {
  color: #888;
  margin-bottom: 5px;
}

.flat-bg-block-v1 .checked-list i {
  color: #face47;
  margin-right: 5px;
}

/*Image Over*/
.flat-bg-block-v1 img.img-over {
  top: -70px;
  right: 10px;
  position: absolute;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .flat-bg-block-v1 img.img-over {
    top: -18px;
  }
}

@media (max-width: 991px) {
  .flat-bg-block-v1 img.img-over {
    position: static;
  }
}

/*--------------------------------------------------
	[18. Flat Testimonials]
----------------------------------------------------*/
.flat-testimonials {
  padding: 50px 0;
  background-color: #333;
}

.flat-testimonials .flat-testimonials-in {
  padding: 30px;
  text-align: center;
  background: rgba(255,255,255,0.2);
}

.flat-testimonials .flat-testimonials-in img {
  width: 135px;
  height: 135px;
  margin: 0 auto 25px;
}

.flat-testimonials .flat-testimonials-in h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
}

.flat-testimonials .flat-testimonials-in span {
  display: block;
  margin-bottom: 5px;
}

.flat-testimonials .flat-testimonials-in p {
  color: #fff;
  font-weight: 200;
  position: relative;
}

.flat-testimonials .flat-testimonials-in p:before,
.flat-testimonials .flat-testimonials-in p:after {
  top: 5px;
  font-size: 22px;
  line-height: 10px;
  position: relative;
}

.flat-testimonials .flat-testimonials-in p:before {
  content: "“";
  padding-right: 5px;
}

.flat-testimonials .flat-testimonials-in p:after {
  content: "”";
  padding-left: 5px;
}

/*--------------------------------------------------
	[19. Testimonials]
----------------------------------------------------*/
/* Testimonials v4
------------------------------------*/
.testimonials-v4 .testimonials-v4-in {
  background: #fff;
  position: relative;
  margin-bottom: 40px;
  padding: 20px 20px 20px 55px;
  box-shadow: 3px 3px 3px 0 rgba(0,0,0,0.1);
}

.testimonials-v4 .testimonials-v4-in:before {
  color: #bbb;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -25px 0 0 -40px;
  font-family: Georgia, serif;
}

.testimonials-v4 .testimonials-v4-in:after {
  width: 0;
  height: 0;
  left: 80px;
  content: " ";
  bottom: -30px;
  position: absolute;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0,0,0,0.1));
  filter: drop-shadow(2px 2px 1px rgba(0,0,0,0.1));
}

.testimonials-v4 .testimonials-v4-in p {
  color: #777;
  font-size: 14px;
  font-style: italic;
}

.testimonials-v4 img {
  float: left;
  width: 60px;
  height: auto;
  margin: 0 20px;
}

.testimonials-v4 .testimonials-author {
  color: #555;
  display: block;
  font-size: 14px;
  overflow: hidden;
  padding-top: 8px;
  text-transform: uppercase;
}

.testimonials-v4 .testimonials-author em {
  font-size: 13px;
  text-transform: capitalize;
}

/*Testimonials v5
------------------------------------*/
.testimonials-v5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.testimonials-v5 .testimonials-desc span {
  font-size: 13px;
  font-weight: 200;
}

.testimonials-v5 .testimonials-desc p {
  padding: 15px;
  position: relative;
  background: #f9f9f9;
  margin-bottom: 25px;
}

.testimonials-v5 .testimonials-desc p:after {
  width: 0;
  height: 0;
  left: 30px;
  color: #fff;
  bottom: -12px;
  content: " ";
  text-align: center;
  position: absolute;
  border-style: solid;
  border-width: 13px 10px 0 10px;
  border-color: #f9f9f9 transparent transparent transparent;
}

/*Testimonials By*/
.testimonials-v5 .testimonials-by {
  margin-left: 20px;
}

.testimonials-v5 .testimonials-by img {
  float: left;
  width: 40px;
  height: auto;
  margin-right: 15px;
}

.testimonials-v5 .testimonials-by small {
  color: #face47;
  font-size: 12px;
}

.testimonials-v5 .testimonials-by span {
  text-transform: uppercase;
}

/*Testimonials v6
------------------------------------*/
.testimonials-v6.testimonials-wrap {
  padding-left: 70px;
  padding-right: 70px;
}

.testimonials-v6 .testimonials-info {
  padding: 20px;
  background: #fff;
  border-bottom: solid 3px #eee;
	transition: all 0.4s ease-in-out;
}

.testimonials-v6 .testimonials-info:hover {
	border-color: #face47;
}

.testimonials-v6 .testimonials-info img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.testimonials-v6 .testimonials-desc {
  overflow: hidden;
}

.testimonials-v6 .testimonials-desc p {
  margin-bottom: 15px;
}

.testimonials-v6 .testimonials-desc strong,
.testimonials-v6 .testimonials-desc span {
  color: #555;
  display: block;
}

@media (max-width: 768px) {
  .testimonials-v6 .testimonials-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

/*Other Testimonials
------------------------------------*/
/* Testimonials v3, v4 */
.testimonials-section3 .owl-stage-outer {
	margin-right: 1px;
}
.testimonials-section3 .testimonials-item,
.testimonials-section4 .testimonials-item {
	border-right: solid 1px #eee;
	padding: 15px 15px 20px;
}
.testimonials-section4 .testimonials-item:last-child {
	border-right: none;
}
.testimonials-section3 img,
.testimonials-section4 img {
	width: 74px !important;
	height: 74px !important;
	border-radius: 50%;
	background: #fff;
	padding: 3px;
	border: solid 1px #E6E6E6;
	margin: 0 auto 5px;
	display: block;
}
.testimonials-section3 blockquote,
.testimonials-section4 blockquote {
	font-size: 15px;
	font-weight: 400;
	font-style: italic;
	line-height: 1.5;
	margin-bottom: 20px;
	border: none;
}
.testimonials-section3 blockquote p,
.testimonials-section4 blockquote p {
	z-index: 9;
	position: relative;
	margin-bottom: 5px;
}
.testimonials-section3 .testimonials-item strong,
.testimonials-section3 .testimonials-item em,
.testimonials-section4 .testimonials-item strong,
.testimonials-section4 .testimonials-item em {
	display: block;
	font-size: 14px;
	font-weight: 700;
}
.testimonials-section3 .testimonials-item strong,
.testimonials-section4 .testimonials-item strong {
	padding-top: 10px;
}
.testimonials-section3 .testimonials-item em,
.testimonials-section4 .testimonials-item em {
	font-style: normal;
	opacity: 0.7;
	font-weight: 400;
}

/* Other Testimonials 1
------------------------------------*/
/*Testimonials*/
.testimonials {
	margin-bottom: 10px;
}

.testimonials .testimonial-info {
	color: #face47;
	font-size: 16px;
	padding: 0 15px;
	margin-top: 18px;
}

.testimonials .testimonial-info span {
	top: 3px;
	position: relative;
}

.testimonials .testimonial-info em {
	color: #777;
	display: block;
	font-size: 13px;
}

.testimonials .testimonial-info img {
	width: 60px;
	float: left;
	height: 60px;
	padding: 2px;
	margin-right: 15px;
	border: solid 1px #ccc;
}

.testimonials .testimonial-author {
	overflow: hidden;
}

.testimonials .carousel-arrow {
	top: -65px;
	position: relative;
}

.testimonials .carousel-arrow i {
	color: #777;
	padding: 2px;
	min-width: 25px;
	font-size: 20px;
	text-align: center;
	background: #f5f5f5;
}

.testimonials .carousel-arrow i:hover {
	color: #fff;
	background: #face47;
}

.testimonials .carousel-control {
	opacity: 1;
	width: 100%;
	text-align: right;
	text-shadow: none;
	position: absolute;
	filter: Alpha(opacity = 100); /*For IE*/
}

.testimonials .carousel-control.left {
	right: 27px;
	left: auto;
}
.testimonials .carousel-control.right {
	right: 0px;
}

/*Testimonials v1*/
.testimonials.testimonials-v1 .item p {
	position: relative;
}

.testimonials.testimonials-v1 .item p:after,
.testimonials.testimonials-v1 .item p:before {
	left: 80px;
	bottom: -20px;
}

.testimonials.testimonials-v1 .item p:after {
	border-top: 22px solid;
	border-left: 0 solid transparent;
	border-right: 22px solid transparent;
}

/*Testimonials v2*/
.testimonials.testimonials-v2 .testimonial-info {
	padding: 0 20px;
}

.testimonials.testimonials-v2 p {
	padding-bottom: 15px;
}

.testimonials.testimonials-v2 .carousel-arrow {
	top: -55px;
}

.testimonials.testimonials-v2 .item p:after,
.testimonials.testimonials-v2 .item p:before {
	left: 8%;
	bottom: 45px;
}

.testimonials.testimonials-v2 .item p:after {
	border-top: 20px solid;
	border-left: 25px solid transparent;
	border-right: 0px solid transparent;
}

/*General Testimonials v1/v2*/
.testimonials.testimonials-v1 p,
.testimonials.testimonials-v2 p {
	padding: 15px;
	font-size: 14px;
	font-style: italic;
	background: #f5f5f5;
}

.testimonials.testimonials-v1 .item p:after,
.testimonials.testimonials-v2 .item p:after {
	width: 0;
	height: 0;
	content: " ";
	display: block;
	position: absolute;
	border-top-color: #f5f5f5;
	border-left-style: inset; /*FF fixes*/
	border-right-style: inset; /*FF fixes*/
}

/*Testimonials Backgrounds*/
.testimonials-bg-dark .item p,
.testimonials-bg-default .item p {
	color: #fff;
	font-weight: 200;
}

.testimonials-bg-dark .carousel-arrow i,
.testimonials-bg-default .carousel-arrow i {
	color: #fff;
}

/*Testimonials Default*/
.testimonials-bg-default .item p {
	background: #face47;
}

.testimonials.testimonials-bg-default .item p:after,
.testimonials.testimonials-bg-default .item p:after {
	border-top-color: #face47;
}

.testimonials-bg-default .carousel-arrow i {
	background: #face47;
}

.testimonials.testimonials-bg-default .carousel-arrow i:hover {
	background: #5fb611;
}

/*Testimonials Dark*/
.testimonials-bg-dark .item p {
	background: #555;
}

.testimonials.testimonials-bg-dark .item p:after,
.testimonials.testimonials-bg-dark .item p:after {
	border-top-color: #555;
}

.testimonials-bg-dark .carousel-arrow i {
	color: #fff;
	background: #555;
}

.testimonials.testimonials-bg-dark .carousel-arrow i:hover {
	background: #333;
}

.testimonials.testimonials-bg-dark .testimonial-info {
	color: #555;
}

/*--------------------------------------------------
	[20. Featured Blog]
----------------------------------------------------*/
.featured-blog h2 {
  font-size: 18px;
  margin: 0 0 25px;
  line-height: 25px;
  position: relative;
}

.featured-blog h2:after {
  left: 0;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -10px;
  position: absolute;
  background: #face47;
}

.featured-blog .featured-img {
  position: relative;
}

.featured-blog .featured-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
}

.featured-blog .featured-img:hover:after {
  background: rgba(0,0,0,0.3);
  transition: all 0.2s ease-in-out;
}

.featured-blog .featured-img > img {
  width: 100%;
}

/*Team Hover Gradient*/
.featured-blog .featured-img i {
  top: 60%;
  left: 50%;
  z-index: 1;
  width: 100%;
  list-style: none;
  text-align: center;
  visibility: hidden;
  position: absolute;
  margin: -17.5px 0 0 -17.5px;
  transition: all 0.2s ease-in-out;
}

.featured-blog .featured-img:hover i {
  top: 50%;
  visibility: visible;
}

.featured-blog .featured-img i {
  color: #fff;
  width: 35px;
  height: 35px;
  padding: 11px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  background: rgba(255,255,255,0.4);
}

.featured-blog .featured-img i:hover {
  color: #fff;
  background: #face47;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*--------------------------------------------------
	[21. Blog Comments]
----------------------------------------------------*/
.blog-comments img {
  width: 80px;
  height: auto;
}

.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent;
}

.blog-comments .comments-itself h3 {
  margin: 0 0 10px;
}

.blog-comments .comments-itself span {
  color: #999;
  float: right;
  font-size: 13px;
}

.blog-comments .comments-itself p {
  color: #999;
}

/*Comments Reply*/
.blog-comments.blog-comments-reply {
  margin-left: 70px;
}

/*Media Queries*/
@media (max-width: 768px) {
  .blog-comments .comments-itself:before {
    display: none;
  }

  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent;
  }
}

/*--------------------------------------------------
	[22. Quote]
----------------------------------------------------*/
.quote-v1 {
  padding: 170px 0 150px;
  text-align: center;
  background: url(../assets/img/patterns/5.png) repeat;
}

.quote-v1 p {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
  padding: 0 100px;
  position: relative;
  margin-bottom: 20px;
}

.quote-v1 p:before {
  color: #face47;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -30px 0 0 -40px;
  font-family: Georgia, serif;
}

.quote-v1 span {
  color: #fff;
  font-size: 18px;
}

@media (max-width: 768px) {
  .quote-v1 p {
    font-size: 18px;
    padding: 0 50px;
  }
}

/*Quote v2
------------------------------------*/
.quote-v2 {
  text-align: center;
  padding: 85px 10% 40px;
}

.quote-v2 p {
  color: #555;
  font-size: 16px;
  font-style: italic;
  position: relative;
  margin-bottom: 20px;
}

.quote-v2 p:before {
  left: 50%;
  top: -75px;
  font-size: 80px;
  content: "\201C";
  position: absolute;
  margin-left: -25px;
  font-family: Georgia, serif;
}

.quote-v2 span {
  color: #555;
  font-weight: 400;
}

/*--------------------------------------------------
	[23. Team Social]
----------------------------------------------------*/
.social-icons-v1 i {
  color: #555;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}

.social-icons-v1 i:hover {
  color: #fff;
  background: #face47;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.social-icons-v1--dark i {
  color: #fff;
  background: #333;
}

/*--------------------------------------------------
	[24. Box Shadows]
----------------------------------------------------*/
.shadow-wrapper {
  z-index: 1;
  position: relative;
}

/*Common Style*/
.box-shadow {
  background: #fff;
  position: relative;
}

.box-shadow:after,
.box-shadow:before {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
}

 /*Effect 1*/
.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
}

 /*Effect 2,3,4*/
.shadow-effect-2 {
  position: relative;
}

.shadow-effect-2:after,
.shadow-effect-2:before,
.shadow-effect-3:before,
.shadow-effect-4:after {
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
}

.shadow-effect-2:after,
.shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
}

/*Effect 5*/
.shadow-effect-5 {
  box-shadow: 0 0 2px #ccc;
}

/*--------------------------------------------------
	[25. Interactive Slider]
----------------------------------------------------*/
/* Interactive Slider v1
------------------------------------*/
.interactive-slider-v1 {
  z-index: 1;
  padding: 130px 0;
  position: relative;
}

.interactive-slider-v1.img-v1 {
  background: url(../assets/img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v1.img-v2 {
  background: url(../assets/img/bg/19.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v1.img-v3 {
  background: url(../assets/img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v1:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.3);
}

.interactive-slider-v1 h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}

.interactive-slider-v1 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .interactive-slider-v1 h2 {
    font-size: 30px;
  }

  .interactive-slider-v1 p {
    font-size: 18px;
  }
}

/*Interactive Slider v2
------------------------------------*/
.interactive-slider-v2 {
  z-index: 1;
  padding: 200px 0;
  position: relative;
  text-align: center;
  background: url(../assets/img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2-md {
  padding: 250px 0;
}

.interactive-slider-v2.img-v1 {
  background: url(../assets/img/bg/25.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.img-v2 {
  background: url(../assets/img/bg/14.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.img-v3 {
  background: url(../assets/img/bg/img8.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.img-v4 {
  background: url(../assets/img/bg/img9.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.interactive-slider-v2.intro-promo-img {
  padding: 80px 0;
  background: url(../assets/img/intro/promo.jpg) repeat-x fixed center center / cover ;
  -webkit-animation: infiniteBackground 65s linear infinite;
  -moz-animation: infiniteBackground 65s linear infinite;
  animation: infiniteBackground 65s linear infinite;
}
@media screen and (max-width: 992px) {
  .interactive-slider-v2.intro-promo-img {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
  }
}
@keyframes infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}
@-webkit-keyframes infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}
@-moz-keyframe infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}

.interactive-slider-v2:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,.4);
}

.intro-promo-cover-dark:after {
  background: rgba(0,0,0,.8);
}
.intro-promo-cover-light:after {
  background: rgba(255,255,255,.9);
}

.interactive-slider-v2 h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  text-transform: uppercase;
}

.interactive-slider-v2 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}

@media (max-width: 768px) {
  .interactive-slider-v2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------
	[26. Blog Trending]
----------------------------------------------------*/
.blog-trending li {
  margin-bottom: 20px;
}

.blog-trending h3 {
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 17px;
}

.blog-trending small,
.blog-trending small a {
  color: #777;
}

.blog-trending small a:hover {
  color: #face47;
}

/*--------------------------------------------------
	[27. Blog Latest Posts]
----------------------------------------------------*/
.blog-latest-posts li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

.blog-latest-posts li:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.blog-latest-posts h3 {
  font-size: 14px;
  margin: 0 0 3px;
  line-height: 20px;
}

.blog-latest-posts small,
.blog-latest-posts small a {
  color: #777;
}

.blog-latest-posts small {
  margin-bottom: 8px;
  display: inline-block;
}

.blog-latest-posts p {
  font-size: 12px;
}

/*--------------------------------------------------
	[28. Blog Photostream]
----------------------------------------------------*/
.blog-photostream {
  margin-right: -5px;
}

.blog-photostream li {
  padding: 0;
  margin: 3px 5px 5px;
  position: relative;
}

.blog-photostream li img {
  width: 78px;
  height: auto;
}

/*--------------------------------------------------
	[29. Newsletter]
----------------------------------------------------*/
.blog-newsletter p {
  color: #555;
  margin-bottom: 15px;
}

.blog-newsletter .form-control {
  border: none;
}

.blog-newsletter .form-control:focus {
  box-shadow: none;
}

/*--------------------------------------------------
	[30. Blog Post Quote]
----------------------------------------------------*/
.blog-post-quote {
  padding: 35px 60px;
  background: #fff;
  text-align: center;
}

.blog-post-quote p {
  color: #555;
  font-size: 32px;
  font-weight: 200;
  line-height: 45px;
  position: relative;
  margin-bottom: 20px;
  font-family: "Open Sans", Arial, sans-serif;
}

@media (max-width: 768px) {
  .blog-post-quote {
    padding: 35px 25px;
  }

  .blog-post-quote p {
    font-size: 26px;
    line-height: 35px;
  }
}

.blog-post-quote p:before,
.blog-post-quote p:after {
  top: -3px;
  font-size: 30px;
  position: relative;
}

.blog-post-quote p:before {
  left: -10px;
  content: "\0022";
}

.blog-post-quote p:after {
  right: -10px;
  content: "\0022";
}

.blog-post-quote span {
  color: #555;
  font-size: 20px;
  font-weight: 200;
}

/*--------------------------------------------------
	[31. Blog Post Author]
----------------------------------------------------*/
.blog-author {
  padding: 20px;
  background: #fff;
}

.blog-author img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.blog-author .blog-author-desc {
  overflow: hidden;
}

.blog-author .blog-author-desc h4 {
  float: left;
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.blog-author .blog-author-desc ul {
  float: right;
  margin-top: 6px;
}

.blog-author .blog-author-desc p {
  color: #555;
}

/*--------------------------------------------------
	[32. Blog Comments]
----------------------------------------------------*/
.blog-comments img {
  width: 80px;
  height: auto;
}

.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent;
}

.blog-comments .comments-itself h3 {
  margin: 0 0 10px;
}

.blog-comments .comments-itself span {
  color: #555;
  float: right;
  font-size: 13px;
}

.blog-comments .comments-itself p {
  color: #555;
}

/*Comments Reply*/
.blog-comments.blog-comments-reply {
  margin-left: 70px;
}

/*Media Queries*/
@media (max-width: 768px) {
	.blog-comments .comments-itself:before {
		display: none;
	}

	.blog-comments .comments-itself:after {
		width: 0;
		height: 0;
		top: -17px;
		left: 20px;
		content: " ";
		position: absolute;
		border-style: solid;
		border-width: 0 15px 17px 15px;
		border-color: transparent transparent #fff transparent;
	}
}

/*--------------------------------------------------
	[33. News]
----------------------------------------------------*/
/*News v1
------------------------------------*/
.news-section {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.news-v1 .news-v1-in {
  background: #fafafa;
}

.news-v1 h3 {
  margin-bottom: 0;
  font-weight: 200;
  line-height: 28px;
  padding: 10px 15px 15px;
}

.news-v1 h3 a {
  color: #555;
}

.news-v1 h3 a:hover {
  color: #face47;
}

.news-v1 p {
  color: #555;
  padding: 0 15px;
  margin-bottom: 20px;
}

.news-v1 .news-v1-info {
  margin-left: 0;
  margin-top: 30px;
  overflow: hidden;
  padding: 8px 10px;
  border-top: solid 1px #eee;
}

.news-v1 .news-v1-info li {
  font-size: 12px;
}

.news-v1 .news-v1-info li a:hover {
  color: #face47;
  text-decoration: none;
}

/*News v2
------------------------------------*/
/*News Badge*/
.news-v2 .news-v2-badge {
  overflow: hidden;
  position: relative;
}

.news-v2 .news-v2-badge p {
  left: 0;
  top: 20px;
  background: #fff;
  padding: 5px 15px;
  text-align: center;
  position: absolute;
}

.news-v2 .news-v2-badge span {
  color: #555;
  display: block;
  font-size: 16px;
  line-height: 16px;
}

.news-v2 .news-v2-badge small {
  color: #555;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}

/*News Description*/
.news-v2 .news-v2-desc {
  padding: 20px;
  background: #fff;
}

.news-v2 .news-v2-desc h3 {
  margin: 0 0 3px;
  font-size: 16px;
}

.news-v2 .news-v2-desc h3 a {
  color: #555;
}

.news-v2 .news-v2-desc h3 a:hover {
  color: #face47;
}

.news-v2 .news-v2-desc small {
  color: #555;
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.news-v2 .news-v2-desc p {
  color: #555;
}

/*News v3
------------------------------------*/
/*News Posts*/
.news-v3 .news-v3-in {
  padding: 35px 30px;
}

.news-v3 .news-v3-in-sm {
  padding: 20px;
}

.news-v3 h2 {
  font-size: 32px;
  font-weight: 200;
  margin: 0 0 20px;
  line-height: 45px;
  text-transform: uppercase;
}

.news-v3 .news-v3-in-sm h2 {
  font-size: 22px;
  margin: 0 0 15px;
  line-height: 30px;
  text-transform: inherit;
}

@media (max-width: 768px) {
  .news-v3 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

.news-v3 h2 a {
  color: #555;
}

.news-v3 h2 a:hover {
  color: #face47;
  text-decoration: none;
}

.news-v3 p {
  color: #555;
  margin-bottom: 20px;
}

/*News Info*/
.news-v3 .posted-info li {
  color: #555;
  font-style: italic;
}

.news-v3 .news-v3-in-sm .posted-info li {
  padding: 0;
  font-size: 12px;
  padding-left: 5px;
}

.news-v3 .posted-info li:before,
.news-v3 .news-v3-in-sm .posted-info li:before {
  content: '/';
}

.news-v3 .posted-info li:before {
  font-size: 12px;
  line-height: 12px;
  margin-right: 13px;
}

.news-v3 .news-v3-in-sm .posted-info li:before {
  font-size: 11px;
  line-height: 11px;
  margin-right: 9px;
}

.news-v3 .posted-info li:first-child:before,
.news-v3 .news-v3-in-sm .posted-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

.news-v3 .posted-info li a {
  color: #555;
}

.news-v3 .posted-info li a:hover {
  color: #face47;
}

/*News Shares*/
.news-v3 .post-shares {
  padding-left: 0;
  list-style: none;
  margin: 40px 0 0 -5px;
}

.news-v3 .news-v3-in-sm .post-shares {
  margin: 20px 0 0 -5px;
}

.news-v3 .post-shares li {
  position: relative;
  padding: 0 10px 0 5px;
  display: inline-block;
}

.news-v3 .post-shares li i {
  color: #555;
  width: 40px;
  height: 40px;
  padding: 13px;
  font-size: 16px;
  background: #eee;
  text-align: center;
  display: inline-block;
}

.news-v3 .post-shares li i:hover {
  background: #ddd;
}

.news-v3 .post-shares li span {
  top: -5px;
  right: 6px;
  color: #fff;
  width: 22px;
  height: 22px;
  padding: 4px;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  text-align: center;
  background: #face47;
  border: 2px solid #fff;
  border-radius: 50% !important;
}

.news-v3 .post-shares-lg li span {
  top: -10px;
  right: 0px;
  width: 29px;
  height: 29px;
  padding: 4px;
  font-size: 10px;
  line-height: 16px;
}

/*--------------------------------------------------
	[34. Blog Grid]
----------------------------------------------------*/
.blog-grid img {
  margin-bottom: 15px;
}

.blog-grid h3 {
  font-size: 22px;
  margin: 0 0 10px;
  line-height: 1.4;
}

.blog-grid h2.blog-grid-title-lg {
  font-size: 28px;
  line-height: 1.4;
  margin: 0 0 10px;
}

.blog-grid h3.blog-grid-title-sm {
  font-size: 15px;
  line-height: 1.4;
}

.blog-grid h3 a {
  color: #333;
}

.blog-grid p {
  margin-bottom: 15px;
}

.blog-grid-inner {
  padding: 20px;
  background: #fff;
  margin: -70px 40px 0;
  position: relative;
}

/*Blog Grid Gradient*/
.blog-grid .blog-grid-grad {
  position: relative;
}

.blog-grid .blog-grid-grad i {
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  display: inline-block;
  background: rgba(0,0,0,0.5);
}

/*Blog Grid Info*/
.blog-grid .blog-grid-info {
  padding-left: 0;
  list-style: none;
}

.blog-grid .blog-grid-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}

.blog-grid .blog-grid-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}

.blog-grid .blog-grid-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

.blog-grid .blog-grid-info li a {
  color: #888;
}

.blog-grid .blog-grid-info li a:hover {
  text-decoration: none;
}

/*Read More Link*/
.blog-grid a.r-more {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  display: inline-block;
}

.blog-grid a.r-more:hover {
  text-decoration: none;
}

/*--------------------------------------------------
  [35. Blog Thumb]
----------------------------------------------------*/
.blog-thumb {
  overflow: hidden;
}

/*Blog Grid Hover*/
.blog-thumb .blog-thumb-hover {
  float: left;
  position: relative;
  margin-right: 15px;
}

.blog-thumb .blog-thumb-hover:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.35s, transform 0.35s;
}

.blog-thumb .blog-thumb-hover img {
  width: 120px;
  height: auto;
}

.blog-thumb .blog-thumb-hover img.video-play-btn {
  width: 30px;
  height: 30px;
}

/*Icon Hover Gradient*/
.blog-thumb .blog-thumb-hover .hover-grad {
  left: 0;
  top: 50%;
  z-index: 1;
  opacity: 0;
  width: 100%;
  color: #fff;
  font-size: 20px;
  margin-top: -14px;
  text-align: center;
  position: absolute;
  display: inline-block;
  transition: all 0.65s, transform 0.65s;
}

.blog-thumb .blog-thumb-hover:hover .hover-grad {
  opacity: 1;
  transform: 0.55s;
  transition: opacity 0.55s,
}

/*Blog Thumb Description*/
.blog-thumb .blog-thumb-desc {
  overflow: hidden;
}

.blog-thumb .blog-thumb-desc h3 {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
  line-height: 1.4;
}

.blog-thumb .blog-thumb-desc h3 a {
  color: #555;
}

/*Blog Grid Info*/
.blog-thumb .blog-thumb-info {
  padding-left: 0;
  list-style: none;
}

.blog-thumb .blog-thumb-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}

.blog-thumb .blog-thumb-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}

.blog-thumb .blog-thumb-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

.blog-thumb .blog-thumb-info li a {
  color: #888;
}

.blog-thumb .blog-thumb-info li a:hover {
  color: #e74c3c;
}

/*Blog Thumb Circle
------------------------------------*/
.blog-thumb.blog-thumb-circle .blog-thumb-hover:after {
  border-radius: 50% !important;
}

.blog-thumb.blog-thumb-circle .blog-thumb-hover img {
  width: 50px;
  height: 50px;
}

.blog-thumb.blog-thumb-circle .blog-thumb-hover .hover-grad {
  font-size: 15px;
  margin-top: -10px;
}

/*Blog Thumb Colors
------------------------------------*/
.blog-thumb .blog-thumb-hover:hover:after {
  background: rgba(114,192,44,0.9);
}
.blog-grid .blog-grid-grad i:hover {
  background: rgba(114,192,44,1);
}

/*--------------------------------------------------
  [36. Shop Subscribe]
----------------------------------------------------*/
.shop-subscribe {
  padding: 25px 0;
}

.shop-subscribe h2 {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
}

/*Input Form Control*/
.shop-subscribe input {
  border-color: #fff;
  border-right: none;
  background: transparent;
}

.shop-subscribe .form-control {
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

.shop-subscribe .form-control:focus {
  box-shadow: none;
  border-color: #fff;
}

.shop-subscribe .form-control::-moz-placeholder {
  color: #fff;
}
.shop-subscribe .form-control:-ms-input-placeholder {
  color: #fff;
}
.shop-subscribe .form-control::-webkit-input-placeholder {
  color: #fff;
}

.shop-subscribe .input-group-btn {
  border-color: #fff;
  background: transparent;
}

.shop-subscribe .input-group-btn .btn {
  border: 1px solid #fff;
  background: transparent;
}

.shop-subscribe .input-group-btn i {
  color: #fff;
  font-size: 16px;
  font-weight: 200;
}

/*--------------------------------------------------
  [37. Parallax Slider (Main)]
----------------------------------------------------*/
.slider-inner .da-slider {
 box-shadow: none;
 border-bottom: 2px solid #ddd;
}

.slider-inner .da-dots {
  z-index: 10;
}

@media (max-width: 450px) {
 .da-slide p {
    display:none;
 }
}

@media (max-width: 900px) {
 /*Slider Parallax*/
 .da-slide .da-img {
    display:none;
 }
}

/*--------------------------------------------------
	[38. Master Slider]
----------------------------------------------------*/
.ms-promo-info {
	font-size: 45px;
	font-weight: 300;
	line-height: 1.5;
	text-transform: uppercase;
	font-family: "Open Sans", Arial, sans-serif;
}

.ms-promo-info-in {
	font-size: 60px;
	font-weight: 600;
}

.ms-promo-sub {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.4;
	font-family: "Open Sans", Arial, sans-serif;
}

.ms-promo-sub-in {
	font-size: 15px;
}

.ms-img-bordered {
	background: #fff;
	padding: 7px;
}

/*--------------------------------------------------
  [39. Revolution Slider]
----------------------------------------------------*/
.tp-dottedoverlay {
  z-index: 1 !important;
}

.tp-bullets.preview4 .bullet {
  border-radius: 10px !important;
}

.tp-banner-container ul li,
.fullscreenbanner-container ul li {
  list-style: none;
  visibility: hidden;
}

.tp-banner-container a.btn-u,
.tp-banner-container a.btn-u:hover {
  color: #fff;
  border: solid 1px transparent;
}

.tp-banner-container a.re-btn-brd {
  background: none;
  border: solid 1px #fff;
}

.tp-banner-container a.re-btn-brd:hover {
  color: #555;
  background: #fff;
}

/*Fullwidth*/
.tp-banner-container .btn-u.btn-brd {
  font-weight: 400;
}

.tp-banner-container {
  position: relative;
}

/*Fullscreen*/
.fullscreen-container {
  padding:0;
  width:100%;
  position:relative;
}

/*Slider Contents v1*/
.revolution-mch-1:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  position: absolute;
  background: rgba(0,0,0,0.3);
}

.revolution-ch1 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

.revolution-ch2 {
  color: #fff;
  font-size: 20px;
  max-width: 470px;
  line-height: 32px;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif;
}

.revolution-ch2:after {
  left: 50%;
  width: 70px;
  height: 2px;
  content: ' ';
  bottom: -20px;
  background: #fff;
  margin-left: -35px;
  position: absolute;
}

/*Slider Contents v2*/
.re-title-v1 {
	color: #fff;
	font-size: 75px;
	font-weight: 600;
	line-height: 60px;
	text-transform: uppercase;
	font-family: "Open Sans", Arial, sans-serif;
}

.re-title-v2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

.re-text-v1 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Open Sans", Arial, sans-serif;
}

.re-text-v2 {
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", Arial, sans-serif;
}

/*Slides*/
.rs-caption-1 {
  color: #fff;
  font-size: 32px;
  font-weight: 200;
  padding: 6px 75px;
  position: relative;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
}

.rs-caption-1:after,
.rs-caption-1:before {
  font-size: 16px;
  content: "\f005";
  position: absolute;
  font-family: FontAwesome;
}

@media (max-width: 768px) {
  .rs-caption-1:after,
  .rs-caption-1:before {
    content: "";
  }
}

.rs-caption-1:after {
  right: 4%;
}

.rs-caption-1:before {
  left: 4%;
}

.rs-caption-2 {
  color: #fff;
  position: relative;
  font-size: 26px;
  font-weight: 200;
  line-height: 40px;
  padding: 10px 50px;
  text-align: center;
}

.rs-caption-2:after {
  left: 50%;
  content: "";
  height: 1px;
  width: 100px;
  bottom: -10px;
  background: #fff;
  margin-left: -50px;
  position: absolute;
}

.rs-caption-3 a {
  margin: 0 5px;
  padding: 7px 20px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .rs-caption-1,
  .rs-caption-2,
  .rs-caption-3 {
    font-weight: normal;
  }
}

/*--------------------------------------------------
  [40. Layer Slider]
----------------------------------------------------*/
.layer_slider {
 overflow: hidden;
}

.ls-ct-half, .ls-ct-center {
 border-radius: 100px !important;
}

/*--------------------------------------------------
  [41. Clients Carousel]
----------------------------------------------------*/
.clients {
  border: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow:none !important;
  margin-bottom: 0 !important;
}
.clients li {
  overflow: hidden;
  margin-left: 1px;
  position: relative;
  background: #fafafa;
}
.clients li:hover {
  background: #f6f6f6;
}
.clients li img.color-img {
  top: 0;
  left: 0;
  display: none;
  position: absolute;
}
.clients li:hover img.color-img {
  display: block;
}
.clients li img {
  filter: gray; /* IE6-9 */
  float: none !important;
  margin: 0 auto !important;
}
.clients li img:hover {
  filter: none;
}

/*--------------------------------------------------
  [42. Pie Chart]
----------------------------------------------------*/
.pie-progress-charts .inner-pchart {
  text-align: center;
}

.pie-progress-charts .circle {
  color: #555;
  font-weight: 200;
  margin-bottom: 20px;
}

.pie-progress-charts .circle-title {
  font-size: 22px;
}

@media (max-width: 767px) {
  .pie-progress-charts .inner-pchart {
    margin-bottom: 40px;
  }
}

/*--------------------------------------------------
  [43. Counter]
----------------------------------------------------*/
.counters {
  color: #555;
  text-align: center;
}

@media (max-width: 767px) {
	.counters {
		margin-bottom: 30px;
	}
	.counters:last-child {
		margin-bottom: inherit;
	}
}

.counters span {
	font-size: 35px;
}

.counters span.counter-icon {
	display: block;
	margin-bottom: 10px;
}

.counters span.counter-icon i {
	color: #fff;
	padding: 16px;
	min-width: 65px;
	font-size: 30px;
	position: relative;
	background: #face47;
}

.counters span.counter-icon i:after {
	width: 0;
	height: 0;
	left: 38%;
	content: " ";
	bottom: -7px;
	display: block;
	position: absolute;
	border-top: 7px solid #face47;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-left-style: inset; /*FF fixes*/
	border-right-style: inset; /*FF fixes*/
}

.counters:hover span.counter-icon i {
	border-color: #000;
}

/*--------------------------------------------------
	[44. Back To Top]
----------------------------------------------------*/
#topcontrol {
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important;
}

#topcontrol:after {
  top: -2px;
  left: 8.5px;
  content: "\f106";
  position: absolute;
  text-align: center;
  font-family: FontAwesome;
}

#topcontrol:hover {
  color: #fff;
  background: #face47;
  transition: all 0.3s ease-in-out;
}

/*--------------------------------------------------
	[45. Owl Carousel]
----------------------------------------------------*/
.owl-btn {
  color: #777;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 9px;
  text-align: center;
  background: #f5f5f5;
}

.owl-btn:hover {
  color: #fff;
  background: #face47;
}

/*Owl Carousel v1*/
.owl-carousel-v1 .owl-navigation {
  text-align: right;
  margin-bottom: 20px;
}

.owl-carousel-v1 .item {
  margin: 0 5px;
}

.owl-carousel-v1 .item img {
  text-align: center;
}

.owl-carousel-v1 .owl-pagination {
  display: none;
}

/*Owl Carousel v2*/
.owl-carousel-v2 .owl-slider-v2 {
  margin-bottom: 20px;
}

.owl-carousel-v2 .item {
  margin: 0 15px;
}

.owl-carousel-v2 .item img {
  text-align: center;
}

.owl-carousel-v2 .owl-navigation {
  text-align: center;
}

.owl-carousel-v2 .owl-pagination {
  display: none;
}

/*Owl Carousel v3*/
.owl-carousel-v3 .item {
  margin: 0 15px;
}

.owl-carousel-v3 .item img {
  text-align: center;
}

.owl-carousel-v3 .owl-pagination {
  display: none;
}

/*Owl Carousel v4*/
.owl-carousel-v4 .owl-wrapper-outer {
  margin-bottom: 20px;
}

.owl-carousel-v4 .item {
  margin: 0 3px;
}

.owl-carousel-v4 .item img {
  width: 100%;
}

/*Owl Carousel v5*/
.owl-carousel-v5 .owl-wrapper-outer {
  margin-bottom: 20px;
}

.owl-carousel-v5 .owl-controls .owl-page.active span,
.owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: #face47;
}

.owl-carousel-v5 .owl-controls .owl-page span {
  margin: 5px 3px !important;
}

/*Owl Slider v6*/
.owl-carousel-v6 .owl-carousel-item {
	margin: 0 10px;
	padding: 7px 0;
	text-align: center;
	position: relative;
	border: 1px solid #e4e2e2;
}

.owl-carousel-v6 .owl-carousel-item img {
	width: 150px;
	height: auto;
}

.owl-carousel-v6 .owl-wrapper-outer {
	margin-bottom: 20px;
}

.owl-carousel-v6 .owl-controls .owl-page span {
	margin: 5px 3px !important;
}

/*Owl Pagination*/
.owl-pagination {
  text-align: center !important;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  filter: Alpha(Opacity=100);/*IE7 fix*/
}

.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
  *display: inline;/*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
  width: 12px;
  height: 12px;
  opacity: 0.3;
  display: block;
  margin: 5px 7px;
  background: #bbb;
  filter: Alpha(Opacity=50);/*IE7 fix*/
  border-radius: 50% !important;
}

/*Owl Carousel Style v1*/
.owl-carousel-style-v1 img {
  width: 120px;
  height: auto;
}

/*Owl Carousel Style v2*/
.owl-carousel-style-v2 .item {
  margin: 0 3px;
  background: #fafafa;
  text-align: center;
  border: solid 1px #eee;
}

.owl-carousel-style-v2 .item img {
  padding: 0 3px;
  display: inline-block;
}

.owl-carousel-style-v2 .item:hover {
  background: #f7f7f7;
  border-color: #e5e5e5;
  transition: all 0.4s ease-in-out;
}

.owl-carousel-style-v2 .owl-pagination {
  display: none;
}

/*Owl Clients v2
------------------------------------*/
.owl-clients-v1 img {
  width: 120px;
  height: auto;
}

.owl-clients-v1 .owl-pagination {
    display: none;
}

/*Owl Carousel - Testimonials v3
------------------------------------*/
/*Testimonials Section*/
.testimonials-v3 {
  padding: 100px 0;
  font-family: "Open Sans", Arial, sans-serif;
}

.testimonials-v3 ul {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
}

.testimonials-v3 li img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.testimonials-v3 li > p {
  font-size: 14px;
}

.testimonials-v3 .testimonials-v3-title {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 20px;
}

.testimonials-v3 .testimonials-v3-title p {
  color: #face47;
  margin-bottom: 0;
}

.testimonials-v3 .testimonials-v3-title span {
  color: #777;
  display: block;
  font-size: 14px;
}

/*Testimonials Owl Navigation*/
.testimonials-v3 .owl-pagination {
  display: none;
}

.testimonials-v3 .owl-buttons .owl-prev,
.testimonials-v3 .owl-buttons .owl-next {
  padding: 13px;
  position: relative;
  margin: 15px 2px 0;
  background: #f5f5f5;
  display: inline-block;
}

.testimonials-v3 .owl-buttons .owl-prev:after,
.testimonials-v3 .owl-buttons .owl-next:after {
	top: 0;
	color: #bbb;
	font-size: 16px;
	position: absolute;
	font-weight: normal;
	display: inline-block;
	font-family: FontAwesome;
}

.testimonials-v3 .owl-buttons .owl-prev:after {
  left: 10px;
  content: "\f104";
}

.testimonials-v3 .owl-buttons .owl-next:after {
  right: 10px;
  content: "\f105";
}

.testimonials-v3 .owl-buttons .owl-prev:hover:after,
.testimonials-v3 .owl-buttons .owl-next:hover:after {
  color: #fff;
}

.testimonials-v3 .owl-buttons .owl-prev:hover,
.testimonials-v3 .owl-buttons .owl-next:hover {
  background: #face47;
  transition: background 0.4s ease-in-out;
}

/*Recent Works (Owl Carousel)
------------------------------------*/
.owl-work-v1 .owl-navigation {
  top: 3px;
  position: relative;
}

.owl-work-v1 .owl-navigation {
  margin-bottom: 10px;
}

.owl-work-v1 .item a {
  text-align: center;
}

.owl-work-v1 .item a:hover {
  text-decoration: none;
}

.owl-work-v1 .item a:hover strong {
  color: #555;
}

.owl-work-v1 .item em.overflow-hidden {
  display: block;
}

.owl-work-v1 .item a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee;
}

.owl-work-v1 .item a strong {
  color: #555;
  display: block;
}

.owl-work-v1 .item a i {
  color: #777;
}

.owl-work-v1 .item a img {
	transition: all 0.8s ease-in-out;
}

.owl-work-v1 .item a:hover img {
	opacity: 0.8;
	transform: scale(1.2) rotate(3deg);
}

.owl-work-v1 .item a:hover span {
	border-bottom: solid 2px #face47;
	transition: all 0.3s ease-in-out;
}

/*Parallax Twitter (Owl Carousel)
------------------------------------*/
.parallax-twitter {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: #333 url(../assets/img/bg/19.jpg) 50% 0 fixed;
  background-size: cover;
  background-position: center center;
}

.parallax-twitter:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.5);
}

.parallax-twitter:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../assets/img/patterns/gridtile.png) repeat;
}

.parallax-twitter-in {
  z-index: 1;
  position: relative;
}

/*Twitter Block (owl-controls)*/
.parallax-twitter-in ul {
  margin: 0;
}

.parallax-twitter-in li {
  color: #fff;
}

.parallax-twitter-in li p {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}

.parallax-twitter-in li a {
  color: #eee;
  text-decoration: underline;
}

.parallax-twitter-in li a:hover {
  text-decoration: none;
}

.parallax-twitter-in li span {
  color: #ddd;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.parallax-twitter-in .owl-page {
  width: 18px;
}

/*Clients Section (Owl Carousel)
------------------------------------*/
.clients-section {
  padding: 60px 0;
  position: relative;
  background: #333 url(../assets/img/bg/7.jpg) 50% fixed;
  background-size: cover;
  background-position: center center;
}

.clients-section:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.8);
}

.clients-section:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../assets/img/patterns/gridtile.png) repeat;
}

.clients-section .title-v1 p,
.clients-section .title-v1 h2 {
  color: #fff;
}

.clients-section ul {
  padding: 0;
  z-index: 1;
  list-style: none;
}

.clients-section li {
  margin: 0 3px;
  background: #fff;
}

.clients-section li a {
  padding: 10px;
  display: block;
  overflow: hidden;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.clients-section li a:hover {
  -transform: scale(1.2);
}

.clients-section img {
  width: 100px;
  height: 100px;
}

.clients-section .owl-pagination {
  display: none;
}

/* Owl Video
------------------------------------*/
.owl-video img {
  position: relative;
  text-align: center;
  margin: 0 auto 20px;
}

.owl-video img.video-play {
  top: 50%;
  left: 50%;
  margin-top: -52px;
  margin-left: -52px;
  position: absolute;
}

.owl-video .owl-controls .owl-page span {
  margin: 5px 3px;
}

/*--------------------------------------------------
	[46. Bootstrap Carousels]
----------------------------------------------------*/
/* Bootstrap Carousel v1
------------------------------------*/
.carousel-v1 .carousel-caption {
	left: 0;
	right: 0;
	bottom: 0;
	padding: 7px 15px;
	background: rgba(0, 0, 0, 0.7);
}

.carousel-v1 .carousel-caption p {
	color: #fff;
	margin-bottom: 0;
}

.carousel-v1 .carousel-arrow a.carousel-control {
	opacity: 1;
	font-size:30px;
	height:inherit;
	width: inherit;
	background: none;
	text-shadow: none;
	position: inherit;
}

.carousel-v1 .carousel-arrow a i {
	top: 50%;
	opacity: 0.6;
	background: #000;
	margin-top: -18px;
	padding: 2px 12px;
	position: absolute;
}
.carousel-v1 .carousel-arrow a i:hover {
	opacity: 0.8;
}

.carousel-v1 .carousel-arrow a.left i {
	left: 0;
}
.carousel-v1 .carousel-arrow a.right i {
	right: 0;
}

/* Bootstrap Carousel v2
------------------------------------*/
.carousel-v2 .carousel-control,
.carousel-v2 .carousel-control:hover {
	opacity: 1;
	text-shadow: none;
}

.carousel-v2 .carousel-control.left,
.carousel-v2 .carousel-control.right {
	top: 50%;
	z-index: 5;
	color: #eee;
	width: 45px;
	height: 45px;
	font-size: 30px;
	margin-top: -22px;
	position: absolute;
	text-align: center;
	display: inline-block;
	border: 2px solid #eee;
	background: rgba(0,0,0,0.1);
}

.carousel-v2 .carousel-control:hover {
	background: rgba(0,0,0,0.3);
	transition: all 0.4s ease-in-out;
}

.carousel-v2 .carousel-control.left {
	left: 20px;
}

.carousel-v2 .carousel-control.right {
	right: 20px;
}

.carousel-v2 .carousel-control .arrow-prev,
.carousel-v2 .carousel-control .arrow-next {
	top: -5px;
	position: relative;
}

.carousel-v2 .carousel-control .arrow-next {
	right: -2px;
}


@media (min-width: 768px) {
	.carousel-indicators {
		bottom: 10px;
	}
}

/*--------------------------------------------------
	[47. Tabs]
----------------------------------------------------*/
/*Tabs*/
.nav-tabs > li > a,
.nav-pills > li > a,
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
}

/*Tabs v1
------------------------------------*/
.tab-v1 .nav-tabs {
	border: none;
	background: none;
	border-bottom: solid 2px #face47;
}

.tab-v1 .nav-tabs a {
	font-size: 14px;
	padding: 5px 15px;
}

.tab-v1 .nav-tabs > .active > a,
.tab-v1 .nav-tabs > .active > a:hover,
.tab-v1 .nav-tabs > .active > a:focus {
	color: #fff;
	border: none;
	background: #face47;
}

.tab-v1 .nav-tabs > li > a {
	border: none;
}
.tab-v1 .nav-tabs > li > a:hover {
	color: #fff;
	background: #face47;
}

.tab-v1 .tab-content {
	padding: 10px 0;
}

.tab-v1 .tab-content img {
	margin-top: 4px;
	margin-bottom: 15px;
}

.tab-v1 .tab-content img.img-tab-space {
	margin-top: 7px;
}

/*Tabs v2
------------------------------------*/
.tab-v2 .nav-tabs {
	border-bottom: none;
}

.tab-v2 .nav-tabs li a {
	padding: 9px 16px;
	background: none;
	border: none;
}

.tab-v2 .nav-tabs li.active a {
	background: #fff;
	padding: 7px 15px 9px;
	border: solid 1px #eee;
	border-top: solid 2px #face47;
	border-bottom: none !important;
}

.tab-v2 .tab-content {
	padding: 10px 16px;
	border: solid 1px #eee;
}

/*Tabs v3
------------------------------------*/
.tab-v3 .nav-pills li a {
	color: #777;
	font-size: 17px;
	padding: 4px 8px;
	margin-bottom: 3px;
	background: #fafafa;
	border: solid 1px #eee;
}

.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
	color: #fff;
	background: #face47;
	border: solid 1px #68af28;
}

.tab-v3 .nav-pills li i {
	width: 1.25em;
	margin-right: 5px;
	text-align: center;
	display: inline-block;
}

.tab-v3 .tab-content {
	padding: 15px;
	background: #fafafa;
	border: solid 1px #eee;
}

/*Tab v4
------------------------------------*/
.tab-v4 .tab-heading {
	width: 100%;
	overflow: hidden;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	border-bottom: 3px solid #333;
}

.tab-v4 .tab-heading h2 {
	margin: 0;
	float: left;
	font-size: 18px;
	font-weight: bold;
}

/*Nav Tabs*/
.tab-v4 .nav-tabs {
	top: 3px;
	border: none;
	float: right;
	overflow: hidden;
	position: relative;
}

.tab-v4 .nav-tabs a {
	color: #555;
	font-size: 12px;
	padding: 6px 15px;
	text-transform: uppercase;
}

.tab-v4 .nav-tabs > .active > a,
.tab-v4 .nav-tabs > .active > a:hover,
.tab-v4 .nav-tabs > .active > a:focus {
	border: none;
	background: inherit;
}

.tab-v4 .nav-tabs > li > a {
	border: none;
}
.tab-v4 .nav-tabs > li > a:hover {
	background: inherit;
}

.tab-v4 .tab-content {
	padding: 20px 0 10px;
}

/*Tab v5
------------------------------------*/
.tab-v5 .nav-tabs {
	border-bottom: none;
}

.tab-v5 .nav-tabs li a {
	border: none;
	font-size: 14px;
	background: none;
	padding: 9px 16px;
}

.tab-v5 .nav-tabs li.active a {
	background: #fff;
	padding: 7px 15px 9px;
	border: solid 1px #eee;
	border-top: solid 3px #222;
	border-bottom: none !important;
}

.tab-v5 .tab-content {
	padding: 13px;
	border: solid 1px #eee;
}

/*Tab v6
------------------------------------*/
.tab-v6 .nav-tabs {
  border-bottom-color: #dedede;
}

.tab-v6 .nav-tabs > li {
  margin-right: 30px;
}

.tab-v6 .nav-tabs > li > a {
  border: none;
  color: #687074;
  padding: 6px 0;
  font-size: 18px;
  margin-right: 0;
  background: none;
  text-transform: uppercase;
  border-bottom: solid 1px transparent;
}

.tab-v6 .nav-tabs > li.active > a,
.tab-v6 .nav-tabs > li.active > a:hover,
.tab-v6 .nav-tabs > li.active > a:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #18ba9b;
}

.tab-v6 .nav-tabs > li > a:hover {
  border-bottom: 1px solid #18ba9b;
}

.tab-v6 .nav-tabs > li > a:focus {
  border: none;
}

.tab-v6 .tab-content {
  padding: 30px 0;
}

/*--------------------------------------------------
	[48. Accordions]
----------------------------------------------------*/
/*Accordion v1
------------------------------------*/
.acc-v1 .panel-heading {
	padding: 0;
	box-shadow: none;
}

.acc-v1 .panel-heading a {
	display: block;
	font-size: 14px;
	padding: 5px 15px;
	background: #fefefe;
}

.acc-icon a.accordion-toggle i {
	color: #555;
	margin-right: 8px;
}
.acc-icon a.accordion-toggle:hover i {
	color: #39414c;
}

/*--------------------------------------------------
	[49. Paginations]
----------------------------------------------------*/

/*Pegination*/
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span,
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 0;
}

.pagination li a {
	color: #777;
	padding: 5px 15px;
}

.pagination li a:hover {
	color: #fff;
	background: #5fb611;
	border-color: #5fb611;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	border-color: #face47;
	background-color: #face47;
}

/*Pagination Without Space*/
.pagination-no-space .pagination {
	margin: 0;
}

/*--------------------------------------------------
	[50. Pagers]
----------------------------------------------------*/
/*Pager*/
.pager li > a,
.pager li > span {
	border-radius: 0;
}

.pager li > a:hover,
.pager li > a:focus {
	color: #fff;
	background: #5fb611;
	border-color: #5fb611;
}

/*Pager v2 and v3
------------------------------------*/
.pager.pager-v2 li > a {
	border: none;
}

.pager.pager-v2 li > a,
.pager.pager-v3 li > a {
	transition: all 0.1s ease-in-out;
}

.pager.pager-v2 li > a:hover,
.pager.pager-v2 li > a:focus,
.pager.pager-v3 li > a:hover,
.pager.pager-v3 li > a:focus {
	color: #fff;
	background: #face47;
}

/*Pager Amount*/
.pager.pager-v2 li.page-amount,
.pager.pager-v3 li.page-amount {
	font-size: 16px;
	font-style: italic;
}

.pager.pager-v2 li.page-amount,
.pager.pager-v2 li.page-amount:hover,
.pager.pager-v2 li.page-amount:focus,
.pager.pager-v3 li.page-amount,
.pager.pager-v3 li.page-amount:hover,
.pager.pager-v3 li.page-amount:focus {
	top: 7px;
	color: #777;
	position: relative;
}

/*Pager Size*/
.pager.pager-v2.pager-md li a,
.pager.pager-v3.pager-md li a {
	font-size: 16px;
	padding: 8px 18px;
}

/*Pager v4
------------------------------------*/
.pager.pager-v4 li > a {
	line-height: normal;
	transition: all 0.1s ease-in-out;
}

.pager.pager-v4 li > a:hover,
.pager.pager-v4 li > a:focus {
	color: #fff;
	background: #222;
	border-color: #222;
}

/*Pager Amount*/
.pager.pager-v4 li.page-amount {
	font-size: 14px;
	font-style: italic;
}

.pager.pager-v4 li.page-amount,
.pager.pager-v4 li.page-amount:hover,
.pager.pager-v4 li.page-amount:focus {
	top: 7px;
	color: #777;
	position: relative;
}

/*--------------------------------------------------
	[51. Sidebar Menu]
----------------------------------------------------*/
/* Sidebar Menu v1
------------------------------------*/
.sidebar-nav-v1 li {
	padding: 0;
}

.sidebar-nav-v1 li a {
	display: block;
	padding: 8px 30px 8px 10px;
}

.sidebar-nav-v1 li a:hover {
	text-decoration: none;
}

.sidebar-nav-v1 > li.active,
.sidebar-nav-v1 > li.active:hover {
	background: #717984;
}

.sidebar-nav-v1 > li.active,
.sidebar-nav-v1 > li.active:hover,
.sidebar-nav-v1 > li.active:focus {
	border-color: #ddd;
}

.sidebar-nav-v1 > li.active > a {
	color: #fff;
}

/*Sidebar Sub Navigation*/
.sidebar-nav-v1 li ul {
	padding: 0;
	list-style: none;
}

.sidebar-nav-v1 li ul,
.sidebar-nav-v1 li.active ul a {
	background: #f8f8f8;
}

.sidebar-nav-v1 li ul a {
	color: #555;
	font-size: 12px;
	border-top: solid 1px #ddd;
	padding: 6px 30px 6px 17px;
}

.sidebar-nav-v1 ul li:hover a,
.sidebar-nav-v1 ul li.active a {
	color: #face47;
}

/*Sidebar Badges*/
.list-group-item li > .badge {
	float: right;
}

.sidebar-nav-v1 span.badge {
	margin-top: 8px;
	margin-right: 10px;
}

.sidebar-nav-v1 .list-toggle > span.badge {
	margin-right: 25px;
}

.sidebar-nav-v1 ul li span.badge {
	margin-top: 8px;
	font-size: 11px;
	padding: 3px 5px;
	margin-right: 10px;
}

/* Sidebar List Toggle
------------------------------------*/
.list-group-item:first-child,
.list-group-item:last-child {
	border-radius: 0;
}

.list-toggle:after {
	top: 7px;
	right: 10px;
	color: #777;
	font-size: 14px;
	content: "\f105";
	position: absolute;
	font-weight: normal;
	display: inline-block;
	font-family: FontAwesome;
}

.list-toggle.active:after {
	color: #fff;
	content: "\f107";
}

/*--------------------------------------------------
	[52. Content Boxes]
----------------------------------------------------*/
/*Content Boxes v1*/
.content-boxes-v1 {
	text-align: center;
}

.content-boxes-v1 span {
	display: block;
	margin-top: 5px;
}

/*Content Boxes v2*/
.content-boxes-v2-o span {
	display: block;
	overflow: hidden;
}

.content-boxes-v2-o small {
	display: block;
	line-height: 1.6;
}

.content-boxes-v2-o .icon-md + span {
	margin-top: 9px;
}

.content-boxes-v2-o .icon-lg + span {
	margin-top: 11px;
}

.content-boxes-v2-o .icon-custom {
	float: left;
	margin-top: 3px;
	margin-right: 15px;
}

@media (max-width: 992px) {
	.content-boxes-v2,
	.content-boxes-v2 .text-justify {
		text-align: center;
	}

	.content-boxes-v2 span {
		display: block;
		margin-top: 5px;
	}
}

/*Content Boxes v3*/
.content-boxes-v3 i.icon-custom {
	top: 8px;
	float: left;
	position: relative;
}

.content-boxes-v3 .content-boxes-in-v3 {
	padding: 0 20px;
	overflow: hidden;
}

.content-boxes-v3 .content-boxes-in-v3 h3 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 3px;
	text-transform: capitalize;
}

.content-boxes-v3 .content-boxes-in-v3 h3 a {
	color: #555;
}

/*Content Boxes Right v3*/
.content-boxes-v3.content-boxes-v3-right {
	text-align: right;
}

.content-boxes-v3.content-boxes-v3-right i.icon-custom {
	float: right;
}

@media (max-width: 768px){
	.content-boxes-v3.content-boxes-v3-right {
		text-align: inherit;
	}

	.content-boxes-v3.content-boxes-v3-right i.icon-custom {
		float: left;
		margin-left: 0;
	}
}

/*Content Boxes v4*/
.content-boxes-v4 h2 {
	color: #555;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}

.content-boxes-v4 a {
	color: #777;
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
}

.content-boxes-v4 i {
	width: 25px;
	color: #face47;
	font-size: 35px;
	margin-top: 10px;
}

.content-boxes-in-v4 {
	padding: 0 10px;
	overflow: hidden;
}

.content-boxes-v4-sm i {
	font-size: 26px;
	margin-top: 10px;
	margin-right: 5px;
}

/*Content Boxes v5*/
.content-boxes-v5 i {
	float: left;
	color: #999;
	width: 50px;
	height: 50px;
	padding: 11px;
	font-size: 22px;
	background: #eee;
	line-height: 28px;
	text-align: center;
	margin-right: 15px;
	display: inline-block;
}

.content-boxes-v5:hover i {
	color: #fff;
	background: #face47;
}

/*Content Boxes v6*/
.content-boxes-v6 {
	padding-top: 25px;
	text-align: center;
}

.content-boxes-v6 i {
	color: #fff;
	width: 90px;
	height: 90px;
	padding: 30px;
	font-size: 30px;
	line-height: 30px;
	position: relative;
	text-align: center;
	background: #dedede;
	margin-bottom: 25px;
	display: inline-block;
}

.content-boxes-v6 i:after {
	top: -8px;
	left: -8px;
	right: -8px;
	bottom: -8px;
	content: " ";
	position: absolute;
	border: 1px solid #dedede;
	border-radius: 50% !important;
}

.content-boxes-v6:hover i,
.content-boxes-v6:hover i:after {
	transition: all 0.3s ease-in-out;
}

.content-boxes-v6:hover i {
	background: #face47;
}

.content-boxes-v6:hover i:after {
	border-color: #face47;
}

/*--------------------------------------------------
	[53. Tagline Boxes]
----------------------------------------------------*/
/*Tagline Boxes*/
.tag-box  {
	padding: 20px;
	background: #fff;
	margin-bottom: 30px;
}

.tag-box h2 {
	font-size: 20px;
	line-height: 25px;
}

.tag-box p {
	margin-bottom: 0;
}

.tag-box.tag-text-space p {
	margin-bottom: 10px;
}

/*Tagline Boxes v1*/
.tag-box-v1 {
	border: solid 1px #eee;
	border-top: solid 2px #face47;
}

/*Tagline Boxes v2*/
.tag-box-v2 {
	background: #fafafa;
	border: solid 1px #eee;
	border-left: solid 2px #face47;
}

/*Tagline Boxes v3*/
.tag-box-v3 {
	border: solid 2px #eee;
}

/*Tagline Boxes v4*/
.tag-box-v4 {
	border: dashed 1px #bbb;
}

/*Tagline Boxes v5*/
.tag-box-v5 {
	margin: 20px 0;
	text-align: center;
	border: dashed 1px #ccc;
}

.tag-box-v5 span {
	color: #555;
	font-size: 28px;
	margin-bottom: 0;
}

/*Tagline Boxes v6*/
.tag-box-v6 {
	background: #fafafa;
	border: solid 1px #eee;
}

/*Tagline Boxes v7*/
.tag-box-v7 {
	border: solid 1px #eee;
	border-bottom: solid 2px #face47;
}

/*--------------------------------------------------
	[54. Tags]
----------------------------------------------------*/
/*Tags v1
------------------------------------*/
.tags-v1 li {
	margin: 0;
	padding: 0;
}

.tags-v1 li a {
	font-size: 13px;
	padding: 4px 8px;
	line-height: 32px;
	border: solid 2px #eee;
	border-radius: 20px !important;
	transition: all 0.2s ease-in-out;
}

.tags-v1 li a:hover {
	text-decoration: none;
	border-color: #e0e0e0;
}

/*Tags v2
------------------------------------*/
.tags-v2 li {
	padding: 7px 0 7px 4px;
}

.tags-v2 li a {
	color: #555;
	font-size: 13px;
	padding: 5px 10px;
	border: solid 1px #bbb;
}

.tags-v2 li a:hover {
	color: #fff;
	background: #555;
	border-color: #555;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

/*Tags v3
------------------------------------*/
.tags-v3 li {
	padding: 7px 0 7px 4px;
}

.tags-v3 li a {
	color: #555;
	font-size: 12px;
	padding: 4px 12px;
	border: solid 1px #eee;
}

.tags-v3 li a:hover {
	color: #fff;
	background: #222;
	border-color: #222;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

/*Tags v4
------------------------------------*/
.tags-v4 {
	padding-left: 0;
	list-style: none;
}

.tags-v4 li {
	padding: 3px 2px;
	line-height: 28px;
	display: inline-block;
}

.tags-v4 a {
	color: #bbb;
	font-size: 13px;
	font-weight: 400;
	padding: 4px 10px;
	border: 1px solid #bbb;
}

.tags-v4 a:hover {
	color: #fff;
	background: #e74c3c;
	border-color: #e74c3c;
	text-decoration: none;
	transition: all 0.25s,
	transform 0.25s;
}

/*--------------------------------------------------
	[##. Main Page Styles]
----------------------------------------------------*/
/* Purchase Block
------------------------------------*/
.purchase {
	padding: 25px 0 30px;
	border-bottom: solid 1px #eee;
	background: url(../assets/img/patterns/breadcrumbs.png) repeat;
}

.purchase p {
	margin-bottom: 0;
}

.purchase span {
	color: #666;
	display: block;
	font-size: 24px;
	line-height: 35px;
	font-weight: normal;
	margin-bottom: 12px;
	font-family: "Open Sans", Arial, sans-serif;
}

.purchase .btn-buy {
	text-align: center;
}

.purchase .btn-buy a {
	margin-top: 27px;
}

.purchase .btn-buy a i {
	margin-right: 5px;
}

@media (max-width: 992px) {
	.purchase {
		text-align: center;
	}

	.purchase span {
		font-size: 22px;
		line-height: 29px;
	}

	.purchase .btn-buy a {
		margin-top: 25px;
		font-size: 16px;
	}
}

/* Service Block
------------------------------------*/
.service {
	overflow: hidden;
	margin-bottom: 10px;
	padding: 15px 15px 10px;
}
.service:hover {
	background: #fcfcfc;
	box-shadow: 0 0 5px #ddd;
	transition: box-shadow 0.2s ease-in-out;
}
.service:hover i {
	color: #656565;
}

.service .desc {
	padding: 0 15px;
	overflow: hidden;
}
.service .desc h4 {
	font-size: 22px;
	line-height: 25px;
}

.service .service-icon {
	float: left;
	padding: 10px;
	color: #face47;
	font-size: 35px;
	text-align: center;
  transition: all 0.4s ease-in-out;
}
.service .service-icon.icon-cogs {
	font-size: 37px;
}
.service .service-icon.icon-plane {
	font-size: 43px;
}

/* Recent Work
------------------------------------*/
.recent-work a {
	text-align: center;
	background: #fcfcfc;
	display: inline-block;
}

.recent-work a:hover {
	text-decoration: none;
}

.recent-work a:hover strong {
	color: #555;
}

.recent-work em.overflow-hidden {
	display: block;
}

.recent-work a span {
	display: block;
	padding: 10px;
	border-bottom: solid 2px #eee;
}

.recent-work a strong {
	color: #555;
	display: block;
}

.recent-work a i {
	color: #777;
}

.recent-work a img {
 transition: all 0.8s ease-in-out;
}

.recent-work a:hover img {
	opacity: 0.8;
 	transform: scale(1.2) rotate(3deg);
}

.recent-work a:hover span {
	border-bottom: solid 2px #face47;
  transition: all 0.3s ease-in-out;
}

/* Work */
.work {
	margin-top: 6px;
}
.work .details {
	padding: 10px;
}
.work .caption {
	display: block;
	padding-top: 5px;
	color: #585f69 !important;
	font-size: 18px !important;
}
.work .caption:hover {
	cursor: pointer;
	color: #444 !important;
	text-decoration: underline;
}

/*Other Footer Common Classes
------------------------------------*/
/*Thumb Headline*/
.thumb-headline h2 {
	color: #eee;
	font-size: 16px;
}

/*Footer Logo*/
.footer-logo {
	margin: 17px 0 20px;
}

/*Latest List*/
.latest-list li {
	padding: 8px 0;
	border-top: 1px solid #353535;
}

.latest-list li:first-child {
	padding-top: 0;
	border-top: none;
}

.latest-list li a {
	color: #eee;
}

.latest-list small {
	color: #999;
	display: block;
}

/*Link List*/
.link-list li {
	border-top: solid 1px #353535;
}

.link-list li:first-child {
	border-top: none !important;
}

.link-list a {
	color: #eee;
	font-size: 11px;
	padding: 6px 0px;
	display: inline-block;
	text-transform: uppercase;
}

.link-list li i {
	color: #bbb;
	float: right;
	margin-top: 10px;
}

/*Latest Tweets*/
.latest-tweets .latest-tweets-inner p {
	color: #555;
	font-size: 13px;
}

.latest-tweets .latest-tweets-inner a {
	/*color: #3498db;*/
	text-decoration: none;
}
.latest-tweets .latest-tweets-inner a:hover {
	text-decoration: underline;
}

.latest-tweets .latest-tweets-inner i.fa {
	top: 2px;
	float: left;
	color: #bbb;
	color: #55acee;
	font-size: 18px;
	margin-right: 5px;
	position: relative;
}

.latest-tweets .latest-tweets-inner small {
	color: #777;
	display: block;
}

/*Social Icons*/
.social-icons {
	margin-top: 5px;
}

.social-icons li {
	margin: 0;
}

.social-icons li a {
	margin: 0 3px;
}

/*Thumbnails
------------------------------------*/
a.thumbnail {
	padding: 0;
	border: none;
	margin-bottom: 15px;
}

.thumbnail-style {
	padding: 7px;
	margin-bottom: 20px;
}
.thumbnail-style:hover {
	box-shadow: 0 0 8px #ddd;
  transition: box-shadow 0.2s ease-in-out;
}

.thumbnail h3,
.thumbnail-style h3 {
	margin: 6px 0 8px 0;
}

.thumbnail h3 a,
.thumbnail-style h3 a {
	color: #585f69;
	font-size: 18px;
}

.thumbnail h3 a:hover,
.thumbnail-style h3 a:hover {
	color: #face47;
	text-decoration: none;
}

.thumbnail-style .thumbnail-img {
	position: relative;
	margin-bottom: 11px;
}

.thumbnail-style a.btn-more {
	right: -10px;
	bottom: 10px;
	color: #fff;
	padding: 1px 6px;
	position: absolute;
	background: #face47;
	display: inline-block;
}
.thumbnail-style a.btn-more:hover {
	text-decoration: none;
	box-shadow: 0 0 0 2px #5fb611;
}
.thumbnail-style:hover a.btn-more {
	right: 10px;
}

.thumbnail-kenburn img {
	left: 10px;
	margin-left: -10px;
	position: relative;
	transition: all 0.8s ease-in-out;
}
.thumbnail-kenburn:hover img {
	transform: scale(1.2) rotate(2deg);
}

/*Servive Blocks
------------------------------------*/
/*Service Alternative Block*/
.service-alternative .service:hover {
	background: #76ca2c;
	transition: all 0.4s ease-in-out;
}
.service-alternative .service:hover i,
.service-alternative .service:hover p,
.service-alternative .service:hover h4 {
	color: #fff;
}

/*Servive Block Versions*/
.service-v1 h2 {
	margin: 15px 0 10px;
	font-size: 20px;
	line-height: 28px;
}

.service-v1 p {
	text-align: justify;
}

/*Blog Posts
------------------------------------*/
.posts .dl-horizontal a {
	color: #555;
}

.posts .dl-horizontal {
	margin-bottom: 15px;
	overflow: hidden;
}

.posts .dl-horizontal dt {
	width: 60px;
	float: left;
}

.posts .dl-horizontal dt img {
	width: 60px;
	height: 60px;
	padding: 2px;
	margin-top: 2px;
	border: solid 1px #ddd;
}

.posts .dl-horizontal dd {
	margin-left: 70px;
}

.posts .dl-horizontal dd p {
	margin: 0;
}

.posts .dl-horizontal dd a {
	font-size: 14px;
	line-height: 16px !important;
}

.posts .dl-horizontal dd a:hover {
	color: #face47;
	text-decoration: none;
}

.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a {
	color: #face47;
	border-color: #face47 !important;
	transition: all 0.2s ease-in-out;
}

/*About Page
------------------------------------*/
.team ul.team-socail {
	text-align: right;
}

.team ul.team-socail li {
	margin: 0 !important;
	padding: 0 !important;
}

.team ul.team-socail li a,
.team ul.team-socail li a:hover {
	text-decoration: none;
}

.team ul.team-socail li i {
	color: #aaa;
	padding: 6px 7px;
	min-width: 30px;
	font-size: 18px;
	text-align: center;
	background: #f7f7f7;
	display: inline-block;
}

.team ul.team-socail li i:hover {
	color: #fff;
	background: #face47;
	transition: all 0.2s ease-in-out;
}

.team .thumbnail-style {
	padding: 12px;
}

.team .thumbnail-style img {
	margin-bottom: 7px;
}

.team .thumbnail-style h3 {
	margin-bottom: 5px;
}

.team .thumbnail-style:hover h3 a {
	color: #face47 !important;
}

.team .thumbnail-style small {
	display: block;
	margin-top: 5px;
	font-size: 12px;
}

/*About Me Page
------------------------------------*/
.about-me {
	overflow: hidden;
}

.about-me h2 {
	margin: 0;
	font-size: 20px;
	text-transform: uppercase;
}

.about-me span {
	color: #999;
	display: block;
	font-size: 14px;
	margin-bottom: 10px;
}

.about-me .social-icons {
	margin-top: 7px;
}

/*About My Goals*/
.about-my-goals h2 {
	text-transform: uppercase;
}

/*About Skills*/
.about-skills {
	padding: 40px 0;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	background: url(../assets/img/breadcrumbs.png) repeat;
}

/*Gallery Page
------------------------------------*/
.gallery-page .thumbnail,
.gallery-page .thumbnail:hover {
	padding: 0;
	border: none;
	box-shadow: none;
}
.gallery-page .thumbnails {
	margin-bottom:6px;
}

/*Privacy Page
------------------------------------*/
.privacy a {
	text-decoration: underline;
}
.privacy a:hover {
	color: #face47;
	text-decoration: none;
}

/*Portfolio Item Page v1
------------------------------------*/
.portfolio-item h2 {
	color: #555;
	font-size: 24px;
	margin: 0 0 15px;
}
.portfolio-item li {
	padding: 3px 0;
}
.portfolio-item p a {
	text-decoration: underline;
}
.portfolio-item p a:hover {
	text-decoration: none;
}

/*Portfolio Item v1*/
.portfolio-item1 ul {
	margin: 10px 0 20px;
}

.portfolio-item1 li {
	font-size: 16px;
	margin-bottom: 3px;
}

.portfolio-item1 li i {
	font-size: 18px;
	margin-right: 4px;
}

/*Sidebar Features
------------------------------------*/
/*Blog Tags*/
ul.blog-tags li {
	display: inline-block;
}

ul.blog-tags a {
	font-size :13px;
	padding: 2px 7px;
	background: #f7f7f7;
	margin: 0 3px 6px 0;
	display: inline-block;
}

ul.blog-tags a i {
	color: #666;
}

ul.blog-tags a:hover {
	background: #face47;
}

ul.blog-tags a:hover,
ul.blog-tags a:hover i {
	color: #fff !important;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

/*Blog Photo Stream*/
.blog-photos li {
	display: inline;
}

.blog-photos li img {
	width: 58px;
	height: 58px;
	margin: 0 2px 8px;
}

.blog-photos li img:hover {
	box-shadow: 0 0 0 2px #face47;
}

/*Blog Latest Tweets*/
.blog-twitter .blog-twitter-inner {
	padding: 10px;
	position: relative;
	background: #f7f7f7;
	margin-bottom: 10px;
}

.blog-twitter .blog-twitter-inner,
.blog-twitter .blog-twitter-inner:after,
.blog-twitter .blog-twitter-inner:before {
	transition: all 0.3s ease-in-out;
}

.blog-twitter .blog-twitter-inner:after,
.blog-twitter .blog-twitter-inner:before {
	width: 0;
	height: 0;
	right: 0px;
	bottom: 0px;
	content: " ";
	display: block;
	position: absolute;
}

.blog-twitter .blog-twitter-inner:after {
	border-top: 15px solid #eee;
	border-right: 15px solid transparent;
	border-left: 0px solid transparent;
	border-left-style: inset; /*FF fixes*/
	border-right-style: inset; /*FF fixes*/
}
.blog-twitter .blog-twitter-inner:before {
	border-bottom: 15px solid #fff;
	border-right: 0 solid transparent;
	border-left: 15px solid transparent;
	border-left-style: inset; /*FF fixes*/
	border-bottom-style: inset; /*FF fixes*/
}

.blog-twitter .blog-twitter-inner:hover {
	border-color: #face47;
	border-top-color: #face47;
}
.blog-twitter .blog-twitter-inner:hover:after {
	border-top-color: #face47;
}

.blog-twitter .blog-twitter-inner span.twitter-time {
	color: #777;
	display: block;
	font-size: 11px;
}

.blog-twitter .blog-twitter-inner a {
	color: #face47;
	text-decoration: none;
}
.blog-twitter .blog-twitter-inner a:hover {
	text-decoration: underline;
}

.blog-twitter .blog-twitter-inner i.fa {
	top: 2px;
	color: #bbb;
	font-size: 18px;
	position: relative;
}

/*Choose Block*/
.who li {
	margin-bottom: 6px;
}
.who li i {
	color: #face47;
	font-size: 13px;
	min-width: 15px;
	margin-right: 8px;
	text-align: center;
	position: relative;
}
.who li:hover i,
.who li:hover a {
	color: #777;
}
.who li:hover a:hover {
	text-decoration: none;
}

/*Promo Page
------------------------------------*/
/*Slider Part*/
.fullwidthbanner-container.slider-promo {
	max-height: 460px !important;
}

.slider-promo-info-bg {
	padding: 25px 20px 20px;
	background: url(../assets/img/bg-black.png) repeat;
}

.slider-promo-info span.big-size {
	color: #fff;
	display: block;
	font-size: 30px;
	margin-bottom: 25px;
	text-shadow: none !important;
}

.slider-promo-info p {
	color: #fff;
	font-size: 16px;
	margin-bottom: 0;
}

/*Promo Box*/
.promo-box {
	padding: 20px;
	margin: 25px 0 20px;
	text-align: center;
}

.promo-box i {
	display: block;
	font-size: 50px;
}

.promo-box p {
	font-size: 14px;
	margin-bottom: 0;
}

.promo-box strong,
.promo-box strong a {
	color: #555;
	display: block;
	font-size: 20px;
	font-weight: 200;
	margin: 20px 0 15px;
}

.promo-box:hover strong,
.promo-box:hover strong a {
	color: #face47;
}

/*Promo Service*/
.promo-service {
	margin-bottom: 20px;
}

.promo-service li {
	margin-bottom: 5px;
}

.promo-service a.btn-u {
	margin: 0 20px 10px 0;
}

/*Promo Elements*/
.title-box {
	font-size: 16px;
	text-align: center;
	padding: 10px 0 30px;
	border-bottom: solid 1px #eee;
}

.title-box .title-box-text {
	color: #555;
	display: block;
	font-size: 35px;
	font-weight: 200;
}

@media (max-width: 768px) {
	.title-box .title-box-text {
		font-size: 26px;
		line-height: 34px;
		margin-bottom: 10px;
	}
	.title-box p {
		font-size: 15px;
	}
}

.block-blockquote {
	margin: 20px 0;
	text-align: center;
	border: dashed 1px #ccc;
}

.block-blockquote-inner {
	padding: 15px;
}

.block-blockquote p {
	font-size: 26px;
	margin-bottom: 0;
}

/*Info Blocks*/
.info-blocks {
	margin-bottom: 15px;
}

.info-blocks:hover i.icon-info-blocks {
	color: #face47;
  transition:all 0.2s ease-in-out;
}

.info-blocks i.icon-info-blocks {
	float: left;
	color: #777;
	font-size: 30px;
	min-width: 50px;
	margin-top: 10px;
	text-align: center;
}

.info-blocks .info-blocks-in {
	padding: 0 10px;
	overflow: hidden;
}

.info-blocks .info-blocks-in h3 {
	color: #555;
	font-size: 20px;
	line-height: 28px;
}

.info-blocks .info-blocks-in p {
	font-size: 12px;
}

/*Brand Page
------------------------------------*/
.brand-page .btn {
	margin-bottom: 10px;
}

/*Form Pages
------------------------------------*/
.form-page input,
.form-page select {
	margin-bottom: 10px;
}

/*Labels and Badges Page
------------------------------------*/
ul.labels-demo {
  margin: 5px 0;
}

ul.labels-demo li {
  list-style: none;
  display: inline-block;
  margin: 0 5px 2px 0;
}

.badge-sidebar {
	border-left: solid 1px #eee;
}

@media (max-width: 991px) {
	.badge-sidebar {
		border-left: none;
	}
}

/*Line Icon Page
------------------------------------*/
.line-icon-page .item-box {
	width: 25%;
	float: left;
	height: 90px;
	text-align: center;
	padding: 20px 10px;
	margin: 0 -1px -1px 0;
	word-wrap: break-word;
	border: 1px solid #ddd;
}
.line-icon-page .item-box--higher {
	height: 120px;
}

@media (max-width: 768px) {
	.line-icon-page .item-box {
		height: 115px;
  	}
}

@media (max-width: 450px) {
	.line-icon-page .item-box {
		height: 130px;
	}
}

.line-icon-page .item-box:hover {
	background: #fafafa;
}

.line-icon-page .item span {
	display: block;
	font-size: 28px;
	margin-bottom: 10px;
}

.line-icon-page .item:hover {
	color: #face47;
}

/*Line Icon Page
------------------------------------*/
.icon-page-fa {
	margin-bottom: 20px;
}

.icon-page-fa .item-box {
	float: left;
	height: 100px;
	width: 16% !important;
}

.icon-page-fa .item {
	font-size: 12px;
}

.icon-page-fa .item i {
	color: #555;
    display: block;
    font-size: 24px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
	.icon-page-fa .item-box {
		width: 20% !important;
	}
}

@media (max-width: 600px) {
 .icon-page-fa .item-box {
  width: 30% !important;
 }
}

@media (max-width: 400px) {
 .icon-page-fa .item-box {
  width: 50% !important;
 }
}

/*GLYPHICONS Icons Page
------------------------------------*/
/*Special styles for displaying the icons and their classes*/
.bs-glyphicons {
	padding-left: 0;
	list-style: none;
	overflow: hidden;
	padding-bottom: 1px;
	margin-bottom: 20px;
}

.bs-glyphicons li {
	width: 25%;
	float: left;
	height: 115px;
	padding: 10px;
	font-size: 11px;
	line-height: 1.4;
	text-align: center;
	margin: 0 -1px -1px 0;
	border: 1px solid #ddd;
}

.bs-glyphicons .glyphicon {
	display: block;
	font-size: 18px;
	margin: 5px auto 10px;
}

.bs-glyphicons li:hover {
	color: #face47;
	background-color: #fafafa;
}

@media (min-width: 768px) {
	.bs-glyphicons li {
	  width: 12.5%;
	}
}

/*Glyphicons Pro*/
.demo-pro-glyphicons li {
	width: 170px;
	height: 100px;
	padding: 20px 10px 30px;
}

.demo-pro-glyphicons span {
	height: 40px;
	display: block;
}

.demo-pro-glyphicons span:before {
	color: #555;
	padding: 10px 62px;
}

.demo-pro-glyphicons span.glyphicons:before {
	font-size: 22px;
}

/*Glyphicons Pro Halflings*/
.demo-pro-glyphicons span.halflings {
	height: 30px;
}

.demo-pro-glyphicons span.halflings:before {
	padding: 10px 70px;
}

/*Contact Pages
------------------------------------*/
.map {
	width: 100%;
	height: 350px;
	border-top: solid 1px #eee;
	border-bottom: solid 1px #eee;
}

.map-box {
	height: 250px;
}

.map-box-space {
	margin-top: 15px;
}

.map-box-space1 {
	margin-top: 7px;
}

/*Background Opacity
------------------------------------*/
.service-or {
	overflow: hidden;
	position: relative;
}

.service-bg {
	width: 250px;
	padding: 60px;
	position: absolute;
	top: -10px;
	right: -100px;
	background-color: rgba(255,255,255,0.1);
	transform: rotate(55deg);
}

/*Easy Block (used for image badges)
------------------------------------*/
.easy-block-v1 {
	position: relative;
}

.easy-block-v1 img {
	width: 100%;
}

.easy-block-v1 .overflow-h h3 {
	width: 60%;
	float: left;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 5px;
}

.easy-block-v1 .easy-block-v1-badge {
	left: 0px;
	top: 10px;
	z-index: 1;
	color: #fff;
	padding: 4px 10px;
	position: absolute;
}

/*Easy Block v2 (used for image badges)
------------------------------------*/
.easy-block-v2 {
	overflow: hidden;
	position: relative;
	margin-bottom: 20px;
}

.easy-block-v2 img {
	width: 100%;
}

.easy-block-v2 .easy-bg-v2 {
	top: 5px;
	color: #fff;
	width: 100px;
	padding: 5px;
	right: -32px;
	font-size: 13px;
	position: absolute;
	text-align: center;
	letter-spacing: 2px;
	transform: rotate(45deg);
}

/*Easy Block v3 (used for image badges)
------------------------------------*/
.easy-block-v3 {
	text-align: left;
	background: #585f69;
	padding: 30px 20px 20px;
}

.easy-block-v3.first {
	background: #696f77;
}
.easy-block-v3.second {
	background: #707780;
}
.easy-block-v3.third {
	background: #787f88;
}

.easy-block-v3 i {
	color: #fff;
	float: left;
	font-size: 30px;
	min-width: 45px;
	padding-top: 10px;
}

.easy-block-v3 .inner-faq-b {
	overflow: hidden;
}

.easy-block-v3 .inner-faq-b h2,
.easy-block-v3 .inner-faq-b h3 {
	font-size: 21px;
	margin-bottom: 8px;
}

.easy-block-v3 .inner-faq-b h4 {
	color: #fff;
	font-size: 17px;
	margin-bottom: 2px;
}

.easy-block-v3 .inner-faq-b h2,
.easy-block-v3 .inner-faq-b h3,
.easy-block-v3 .inner-faq-b p {
	color: #fff;
}

/*Full Width Block
------------------------------------*/
.full-w-block {
	padding: 30px 0;
	background: #282828;
}

.full-w-block .info-blocks i,
.full-w-block .info-blocks-in h3 {
	color: #fff;
}

.full-w-block .info-blocks-in p {
	color: #ccc;
}

.full-w-block .info-blocks:hover i.icon-info-blocks {
	color: #fff;
}

/*Simple Block
------------------------------------*/
.simple-block {
	margin-bottom: 20px;
}

.simple-block img {
	width: 100%;
}

.simple-block p {
	text-align: center;
}

.simple-block .carousel-indicators {
	top: 10px;
	left: 65%;
	text-align: right;
}

.simple-block .carousel-indicators .active {
	background: #555;
}

.simple-block .carousel-indicators li {
	border-color: #555;
}

.simple-block .carousel-inner > .item {
	margin: 0;
}

.simple-block .responsive-video {
	margin-bottom: 3px;
}

/*Two Blocks
------------------------------------*/
.two-blocks {
	text-align: center;
}

.two-blocks-in {
	margin-bottom: 30px;
}

.two-blocks-in i {
	color: #fff;
	padding: 11px;
	font-size: 30px;
	min-width: 50px;
	text-align: center;
	background: #585f69;
	margin-bottom: 10px;
	display: inline-block;
}

/*Three Blocks
------------------------------------*/
.three-blocks {
	text-align: center;
}

.three-blocks-in {
	margin-bottom: 30px;
}

.three-blocks-in i {
	color: #777;
	padding: 9px 6px;
	font-size: 20px;
	min-width: 40px;
	margin-bottom: 10px;
	border: 1px solid #777;
}

.three-blocks-in h3 {
	font-size: 20px;
	margin-bottom: 7px;
}

/*Temperory Classes
------------------------------------*/
/*Banner-info*/
.banner-info {
	margin-bottom: 10px;
}

.banner-info i {
	float: left;
	color: #fff;
	padding: 11px;
	/*min-width works with line-icon*/
	min-width: 40px;
	font-size: 22px;
	text-align: center;
	margin: 7px 20px 0 0;
}

/*min-width works with FontAwesome*/
.banner-info i.fa {
	min-width: 46px;
}

.banner-info.light i {
	border: 1px solid #fff;
}

.banner-info.dark i {
	background: #555;
}

.banner-info h3 {
	font-size: 21px;
	margin-bottom: 5px;
}

.banner-info.light h3 { color: #fff;}
.banner-info.light p { color: #eee;}

/*Title-Box-V2*/
.title-box-v2 {
	text-align: center;
	margin-bottom: 40px;
}

.title-box-v2 h2 {
    font-size: 25px;
    text-transform: uppercase;
}

.title-box-v2 p {
	font-size: 13px;
}

/*remove padding from "Col-"*/
.no-space {
	padding: 0;
}

.no-space-left {
	padding-left: 0;
	padding-right: 15px !important;
}

.no-space-right {
	padding-right: 0;
	padding-left: 15px !important;
}

/*List Style v1*/
.list-style-v1 li {
	margin-bottom: 7px;
}

.list-style-v1 i {
	margin-right: 5px;
}

/*Social Contacts
------------------------------------*/
.social-contacts li {
	padding: 5px 0;
	border-top: 1px solid #ddd;
}

.social-contacts li:first-child {
	border-top: none;
}

.social-contacts li i {
	color: #777;
	font-size: 13px;
	min-width: 13px;
	margin-right: 12px;
	text-align: center;
}

/*Status Bar
------------------------------------*/
.status-bar li {
	padding-left: 9px;
	border-left: 1px solid #eee;
}

.status-bar li:first-child {
	border-left: none;
}

.status-bar li i {
	color: #face47;
	margin-right: 5px;
}

.status-bar li a:hover {
	text-decoration: none;
}

.status-bar-in {
	padding: 10px;
	position: relative;
	background: #f0f0f0;
}

.status-bar-in:after {
	width: 0;
	height: 0;
	top: -10px;
	left: 50px;
	content: " ";
	display: block;
	position: absolute;
	border-bottom: 11px solid #f0f0f0;
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-left-style: inset; /*FF fixes*/
	border-right-style: inset; /*FF fixes*/
}

/*Share List*/
.share-list {
	margin-bottom: 0;
}

.share-list li {
	padding-left: 20px;
}

.share-list li:first-child {
	padding-left: 0;
}

.share-list li i {
	color: #face47;
	margin-right: 5px;
}

/*People Say
------------------------------------*/
.people-say img {
	float: left;
	width: 40px;
	height: auto;
	margin: 6px 10px 0 0;
}

.people-say .overflow-h span {
	font-weight: 700;
}

.people-say .overflow-h p {
	font-style: italic;
	line-height: 1.3;
}

.people-say .overflow-h small {
	text-align: right;
	font-style: italic;
}

/*Inspire Page
------------------------------------*/
img.img-mouse {
	width: 150px;
	height: auto;
	margin: 0 auto 60px;
}

/*Desire Page
------------------------------------*/
/*Service Info*/
.service-info {
	position: relative;
	padding: 40px 0 50px;
	background: url(../assets/img/patterns/17.png) repeat;
}

.service-info .info-description {
	overflow: hidden;
}

.service-info i.service-info-icon {
	color: #777;
	float: left;
	width: 50px;
	height: 50px;
	padding: 15px;
	font-size: 20px;
	line-height: 17px;
	margin-right: 20px;
	text-align: center;
	display: inline-block;
	border: 1px solid #777;
}

.service-info .info-description h3 {
	margin: 0 0 10px;
}

.service-info .info-description p {
	color: #777;
}

/*Portfolio Single Item Page
------------------------------------*/
/*Project Details*/
.project-details li {
  color: #555;
  font-weight: 200;
  margin-bottom: 5px;
}

.project-details strong {
  min-width: 100px;
  display: inline-block;
}

.project-details a {
  text-decoration: underline;
}

/*Bootstrap Testimonials
------------------------------------*/
.testimonials-bs {
  padding: 50px 0;
  background: url(../assets/img/bg/16.jpg) 50% 0 repeat fixed;
}

.testimonials-bs .headline-center-v2 span.author {
  color: #777;
  font-size: 14px;
}

/*Removes Bootstrap carousel background images*/
.testimonials-bs .item {
  margin-bottom: 30px;
}

.testimonials-bs .carousel-arrow {
  text-align: center;
}

.testimonials-bs .carousel-control-v2 i {
	color: #888;
	width: 30px;
	height: 30px;
	padding: 2px;
	font-size: 22px;
	display: inline-block;
	border: 2px solid #888;
}

.testimonials-bs .carousel-control-v2 i:hover {
  color: #face47;
  border-color: #face47;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.testimonials-bs .carousel-control-v2.left,
.testimonials-bs .carousel-control-v2.right {
    text-decoration: none;
    background-image: none;
}

/*Team v1*/
.team-v1 li > h3 {
	color: #555;
}
