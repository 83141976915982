/*Footer-v1
------------------------------------*/
.footer-v1 .footer {
	padding: 40px 0;	
	background: #272727;
}

.footer-v1 .footer p {
	color: #eee;
}

.footer-v1 .footer h2 {
	color: #eee;
	font-size: 18px;
	font-weight: 200;
}

/*Link List*/
.footer-v1 .link-list li {
	border-top: solid 1px #353535;
}

.footer-v1 address,
.footer-v1 .link-list a,
.footer-v1 .link-list i {
	color: #eee;
}

.footer-v1 address {
	line-height: 23px;
}

/*Dotted Map*/
.footer-v1 .footer .map-img {
	background: url(../../assets/img/map-img.png) 5px 60px no-repeat;
}

/*Blog Posts (with images)*/
.footer-v1 .footer .posts .dl-horizontal a {
 color: #dadada;
}

.footer-v1 .footer .dl-horizontal a:hover {
 color: #5fb611 !important;
}

/*Copyright*/
.footer-v1 .copyright {
	background: #222;
	border-top: none;
	padding: 10px 0 5px;
}

.footer-v1 .copyright p {
	color: #eee;
	margin-top: 14px;
}

.footer-v1 .copyright a {
	margin: 0 2px;
}

.footer-v1 .footer-socials {
	margin-top: 12px;
	text-align: right;
}

.footer-v1 .footer-socials li a {
	color: #777;
	font-size: 16px;
}

@media (max-width: 991px) {
	.footer-v1 .copyright,
	.footer-v1 .footer-socials {
		text-align: left;
	}
}