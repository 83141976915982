/*Profile
------------------------------------*/
.profile .list-group i {
	min-width: 20px;
	margin-right: 5px;
	text-align: center;
}

.profile .heading-sm-v2 {
	font-size: 16px;
}

.profile .profile-body {
	padding: 20px;
	background: #f7f7f7;
}

/*Scrollbar*/
.mCustomScrollbar {
	height: 320px; 
}

/*Panel Title Box*/
.profile .panel-profile {
	border: none;
	margin-bottom: 0;
	box-shadow: none;
}
.profile .panel-profile.no-bg {
	background: inherit;
}

.profile .panel-heading {
	color: #585f69;
	background: #fff;
	padding: 7px 15px;
	border-bottom: solid 3px #f7f7f7;
}

.profile .panel-heading-v2.overflow-h i.pull-right,
.profile .panel-heading.overflow-h i.pull-right {
	top: 7px;
	position: relative;
}

.profile .panel-heading-v2.overflow-h i.pull-right,
.profile .panel-heading.overflow-h a i {
	color: #777;
}

.profile .panel-heading-v2.overflow-h i.pull-right,
.profile .panel-heading.overflow-h a:hover i {
	color: #555;
}

.profile .panel-title {
	font-size: 16px;
}

/*Notification*/
.profile .notification {
	overflow: hidden;
	margin-bottom: 10px;
	padding: 15px 15px 0 0;
	border-top: 1px solid #eee;
}

.profile .notification:first-child {
	border-top: none;
}

.profile .notification img,
.profile .notification i {
	float: left;
	margin-right: 10px;
}

.profile .notification img {
	width: 35px;
	height: 35px;
}

.profile .notification span {
	color: #777;
	display: block;
}

.profile .notification small {
	color: #999;
	font-size: 11px;
}

.profile .notification h4 {
	margin: 0 0 3px;
	font-size: 14px;
}

.profile .notification p {
	color: #777;
	font-size: 12px;
}

/*Profile Post*/
.profile .profile-post {
	background: #fff;
	margin-bottom: 2px;
	padding: 7px 7px 7px 0;
}

.profile .profile-post.color-one {
	border-left: 2px solid #face47;
}
.profile .profile-post.color-two {
	border-left: 2px solid #3498db;
}
.profile .profile-post.color-three {
	border-left: 2px solid #e74c3c;
}
.profile .profile-post.color-four {
	border-left: 2px solid #9b6bcc;
}
.profile .profile-post.color-five {
	border-left: 2px solid #555;
}
.profile .profile-post.color-six {
	border-left: 2px solid #f1c40f;
}
.profile .profile-post.color-seven {
	border-left: 2px solid #27d7e7;
}

.profile .profile-post span.profile-post-numb {
	float: left;
	color: #999;
	font-size: 20px;
	font-weight: 200;
	padding: 5px 12px;
}

.profile .profile-post:hover span.profile-post-numb {
	color: #face47;
}

.profile .profile-post-in {
	overflow: hidden;
}

.profile .profile-post-in h3 a {
	color: #555;
	text-transform: uppercase;
}

.profile .profile-post-in p {
	color: #999;
	margin-bottom: 5px;
}

/*Profile Event*/
.profile .profile-event .heading-xs {
	margin-top: 0;
	text-transform: uppercase;
}

.profile .profile-event {
	background: #fff;
	padding: 10px 15px;
	margin-bottom: 2px;
}

.profile .profile-event p {
	color: #999;
	margin-bottom: 5px;
}

.profile .date-formats {
	color: #fff;
	float: left;
	text-align: center;
	background: #face47;
	margin: 7px 13px 0 0;
	padding: 7px 10px 4px;
}

.profile .date-formats span {
	display: block;
	font-size: 22px;
	font-weight: 200;
	line-height: 25px;
}

/*Table*/
.profile .table {
	background: #fff;
}

.profile .table th {
	min-width: 100px;
}

.profile .table td {
	vertical-align: middle;
}

.profile .table h3 {
	margin-top: 0;
	font-size: 16px;
	margin-bottom: 5px;
}

.profile .table a {
	color: #555;
}

.profile .table-search-v2 td img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
	display: block;
}

.profile .table > thead > tr > th, 
.profile .table > tbody > tr > th, 
.profile .table > tfoot > tr > th, 
.profile .table > thead > tr > td, 
.profile .table > tbody > tr > td, 
.profile .table > tfoot > tr > td {
	padding: 11px;
}

/*Comment*/
.profile .comment {
	border-bottom: 1px solid #eee;
	padding: 15px 15px 5px;
}

.profile .comment img {
	float: left;
	width: 50px;
	height: 50px;
	margin-right: 15px;
}

.profile .comment strong {
	display: block;
	line-height: 15px;
	margin-bottom: 3px;
}

.profile .comment p {
	font-size: 12px;
	margin-bottom: 0;
	line-height: 17px;
}

.profile .comment small {
	color: #bbb;
	font-weight: 200;
}

.profile .comment-list li {
	color: #999;
	font-size: 11px;
}

.profile .comment-list li a {
	color: #555;
}

.profile .comment-list li a:hover {
	color: #555;
	text-decoration: none;
}

.profile .comment-list li:hover i {
	color: #face47;
}

/*Image Post*/
.profile .img-post {
	position: relative;
}

.profile .img-post:before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: " ";
	position: absolute;
	background: rgba(0,0,0,0.4);
}

.profile .img-post-in {
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px;
	position: absolute;
}

.profile .img-post-in h3 {
	color: #fff;
	margin: 0 auto;
	font-weight: 200;
}

.profile .img-post-in small {
	color: #999;
	display: block;
	margin-bottom: 15px;
}

.profile .img-post-in p {
	color: #bbb;
}

.profile .img-post-list {
	margin-bottom: 0;
}

.profile .img-post-list li a,
.profile .img-post-list li a:hover {
	color: #fff;
	text-decoration: none;
}
.profile .img-post-list li a:hover i {
	color: #face47;
}

/*Media*/
.profile .media.media-v2 {
	margin-top: 0;
	padding: 25px 0 20px;
	border-top: 1px solid #eee;
}

.profile .media.media-v2:first-child {
	border-top: none;
}

.profile .media.media-v2 .media.media-v2 {
	margin-top: 20px;
	padding-bottom: 0;
}

.profile .media.media-v2 img.media-object {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}

/*Media Body*/
.profile .media.media-v2 .media-body {
	overflow: hidden;
}

.profile .media.media-v2 .media-body p {
	color: #777;
	line-height: 1.4;
}

/*Media Heading*/
.profile .media.media-v2 .media-heading {
	margin: 0 0 5px;
	font-size: 13px;
	position: relative;
}

.profile .media.media-v2 .media-heading a,
.profile .media.media-v2 .media-heading a:hover {
	color: #555;
}

.profile .media.media-v2 .media-heading strong {
	padding-right: 5px;
}

.profile .media.media-v2 .media-heading small {
	top: 7px;
	right: 0;
	color: #999;
	font-size: 12px;
	position: absolute;
}

/*Results List*/
.profile .results-list {
	margin-top: 4px;
}

.profile .results-list li a {
	color: #999;
}

/*Expand List*/
.profile i.expand-list {
	color: #777;
	padding: 5px;
	min-width: 20px;
	font-size: 12px;
	text-align: center;
	display: inline-block;
	border: 1px solid #777;
}

.profile i.expand-list:hover {
	color: #999;
	border-color: #999;
}

/*Image Uploaded*/
.profile .img-uploaded img {
	width: 120px;
	height: auto;
}

/*Alert Blocks*/
.alert-blocks {
	margin: 0 15px 5px;
	background: #f7f7f7;
	padding: 10px 10px 13px;
}

.alert-blocks:first-child {
	margin-top: 15px;
}

.alert-blocks-pending {
	background: #fffdcc; 
}

.alert-blocks-success {
	background: #e1febc; 
}

.alert-blocks-pending {
	background: #fffdcc; 
}

.alert-blocks-error {
	background: #fee0e0; 
}

.alert-blocks-info {
	background: #e0f3fe; 
}

.alert-blocks.alert-dismissable {
  	padding-right: 35px;
}

.alert-blocks i {
	float: left;
	margin-right: 15px;
}

.alert-blocks img {
	float: left;
	width: 40px;
	height: 40px;
	margin-right: 15px;
}

.alert-blocks strong small {
	color: #a3a3a3;
	font-weight: 200;
}

.alert-blocks p {
	color: #999;
	margin-bottom: 0;
}

/*Profile Me
------------------------------------*/
/*Profile Biography*/
.profile .profile-bio {
	background: #fff;
	position: relative;
	padding: 15px 10px 5px 15px;
}

.profile .profile-bio a {
	left: 50%;
	bottom: 20px;
	margin-left: -60px;
	text-align: center;
	position: absolute;
}

.profile .profile-bio h2 {
	margin-top: 0;
	font-weight: 200;
}

.profile .profile-bio span {
	display: block;
}

.profile .profile-bio hr {
	margin: 12px 0 10px;
}

.profile .p-chart {
	text-align: center;
}

.profile .p-chart h3 {
	line-height: 20px;
	margin-bottom: 10px;
}

/*Circle in Responsive*/
@media (max-width: 450px) {
	.p-chart.col-xs-6 {
		width: 100%;
	}
}

/*Timeline*/
.profile .timeline-v2.timeline-me:before {
	left: 19%;
}

.profile .timeline-v2.timeline-me p {
	margin-bottom: 0;
}

.profile .timeline-v2.timeline-me > li .cbp_tmtime {
	width: 27%;
}

.profile .timeline-v2.timeline-me > li .cbp_tmicon {
	left: 19%;
}

.profile .timeline-v2.timeline-me > li .cbp_tmtime span:first-child {
	color: #585f69;
	font-size: 16px;
	text-align: left;
	line-height: 20px;
}

.profile .timeline-v2.timeline-me > li .cbp_tmtime span:last-child {
	color: #999;
	font-size: 14px;
	text-align: left;
}

.profile .timeline-v2.timeline-me > li .cbp_tmlabel {
	padding: 0;
	background: inherit;
	margin: 0 0 20px 23%;
}

.profile .timeline-v2.timeline-me > li .cbp_tmlabel:after {
	border-color: inherit;
}

.profile .timeline-v2.timeline-me > li .cbp_tmlabel h2 {
	margin: 0 auto;
	font-size: 17px;
	line-height: 20px;
	border-bottom: none;
}

/*Social Contacts v2*/
.profile .social-contacts-v2 {
	margin-bottom: 0;
}

.profile .social-contacts-v2 li {
	padding: 7px 0;
	border-top: 1px solid #f0f0f0;
}

.profile .social-contacts-v2 li:first-child {
	border-top: none;
}

.profile .social-contacts-v2 li i {
	font-size: 16px;
	min-width: 25px;
	margin-right: 7px;
	text-align: center;
	display: inline-block;
}

.profile .social-contacts-v2 li i.tw {
	color: #159ceb;
}
.profile .social-contacts-v2 li i.fb {
	color: #4862a3;
}
.profile .social-contacts-v2 li i.sk {
	color: #00aceb;
}
.profile .social-contacts-v2 li i.gp {
	color: #dc4a38;
}
.profile .social-contacts-v2 li i.gm {
	color: #c6574b;
}

/*Social Contacts v3*/
.profile .social-contacts-v3 {
	margin-bottom: 0;
}

.profile .social-contacts-v3 li {
	padding: 12px 0;
	border-top: 1px solid #f0f0f0;
}

.profile .social-contacts-v3 li:first-child {
	padding-top: 0;
	border-top: none;
}

.profile .social-contacts-v3 li:last-child {
	padding-bottom: 0;
}

.profile .social-contacts-v3 li i {
	color: #fff;
	width: 25px;
	height: 25px;
	line-height: 25px;
	margin-right: 7px;
	text-align: center;
	display: inline-block;
}

.profile .social-contacts-v3 li i.tw {
	background: #159ceb;
}
.profile .social-contacts-v3 li i.fb {
	background: #4862a3;
}
.profile .social-contacts-v3 li i.sk {
	background: #00aceb;
}
.profile .social-contacts-v3 li i.gp {
	background: #dc4a38;
}
.profile .social-contacts-v3 li i.gm {
	background: #c6574b;
}

/*Profile Blog
------------------------------------*/
.profile .blog-border {
	border: 1px solid #f0f0f0;
}

.profile .profile-blog {
	padding: 20px;
	background: #fff;
}

.profile .profile-blog img {
	float: left;
	width: 50px;
	height: 50px;
	margin-right: 20px;
}

.profile .profile-blog hr {
	margin: 12px 0 10px;
}

.profile .name-location {
	overflow: hidden;
}

.profile .name-location strong {
	color: #555;
	display: block;
	font-size: 16px;
}

.profile .name-location span a {
	color: #555;
}

.profile .name-location span a:hover {
	color: #face47;
}

.profile .name-location span i {
	color: #face47;
	font-size: 18px;
	margin-right: 5px;
}

/*Profile Projects
------------------------------------*/
.profile .projects {
	background: #fff;
	padding: 20px 20px 10px;
}

.profile .projects h2 {
	margin: 0 0 5px;
	font-size: 22px;
	font-weight: 200;
}

.profile .projects h3 {
	margin-bottom: 5px;
}

.profile .projects .progress {
	margin-bottom: 15px;
}

.profile .blog-info-v2 {
	margin-left: 0;
	margin-bottom: 15px;
}

.profile .blog-info-v2 li {
	font-size: 12px;
	padding-left: 8px;
	line-height: 12px;
	border-left: 1px solid #eee;
}

.profile .blog-info-v2 li:first-child {
	padding-left: 0;
	border-left: none;
}

.profile .blog-info-v2 li i {
	margin-right: 3px;
}

.profile .project-share {
	overflow: hidden;
	background: #fff;
	padding: 10px 20px;
	border-top: 1px solid #eee;
}

.profile .project-share ul {
	margin-left: 0;
	margin-bottom: 0;
}

.profile .comment-list-v2 li {
	color: #999;
	font-size: 14px;
	padding: 0 10px 0 0;
}

.profile .comment-list-v2 li a {
	color: #555;
}

.profile .comment-list-v2 li i {
	margin-right: 3px;
}

.profile .comment-list-v2 li a:hover {
	color: #555;
	text-decoration: none;
}

.profile .comment-list-v2 li:hover i {
	color: #face47;
}

.profile .star-vote li {
	padding: 0;
	font-size: 15px;
}

/*Profile History
------------------------------------*/
.profile .timeline-v2:before {
	background: #eee;
}

.profile .timeline-v2 > li .cbp_tmlabel {
	background: #fff;
}

.profile .timeline-v2 > li .cbp_tmlabel:after {
	border-right-color: #fff;
}

@media screen and (max-width: 767px) {
	.profile .timeline-v2 > li .cbp_tmlabel:after {
		border-bottom-color: #fff;
		border-right-color: transparent;
	}
}

@media screen (min-width: 768px) and (max-width: 992px) {
	.profile .timeline-v2 > li .cbp_tmlabel:after {
		border-bottom-color: #fff;
		border-right-color: transparent;
	}
}

/*Profile Settings
------------------------------------*/
/*Profile Edit*/
.profile .profile-edit {
	padding: 20px;
	background: #fff;
}

.profile .profile-edit h2 {
	font-weight: 200;
}

.profile .profile-edit dt {
	text-align: inherit;
}

.profile .profile-edit hr {
	margin: 17px 0 15px;
}

/*Tab*/
.profile .tab-content {
	padding: 0;
}

@media (max-width: 768px) {
	.profile .tab-v1 .nav-tabs { 
	  	border-bottom: none;   
	}
}

/*Sky Form*/
.profile .sky-form {
	border: none;
}

.profile .sky-form .toggle-change i {
	left: 180px;
}